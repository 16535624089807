import {
  SET_LICENSE_TYPE,
  SET_USER,
  SET_ADMIN,
  SET_SUBSCRIPTION_OWNER,
  SET_ACCESS_TOKEN,
  SET_ANALYSIS_ENGINE,
} from "../constants/actionTypes";
import { LICENSE_TYPES, IUser } from "../interfaces/IUserInterface";

export type setLicenseTypeType = (payload: LICENSE_TYPES) => void;
export const setLicenseType: setLicenseTypeType = (payload) => ({
  type: SET_LICENSE_TYPE,
  payload,
});

export type setUserType = (payload: IUser) => void;
export const setUser: setUserType = (payload) => ({
  type: SET_USER,
  payload,
});

export type setAdminType = (payload: boolean) => void;
export const setAdmin: setAdminType = (payload) => ({
  type: SET_ADMIN,
  payload,
});

export type setSubscriptionOwnerType = (payload: boolean) => void;
export const setSubscriptionOwner: setSubscriptionOwnerType = (payload) => ({
  type: SET_SUBSCRIPTION_OWNER,
  payload,
});

export type setAccessTokenType = (payload: boolean) => void;
export const setAccessToken: setAccessTokenType = (payload) => ({
  type: SET_ACCESS_TOKEN,
  payload,
});

export type setAnalysisEngine = (payload: boolean) => void;
export const setAnalysisEngine: setAnalysisEngineType = (payload) => ({
  type: SET_ANALYSIS_ENGINE,
  payload,
});
