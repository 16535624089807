import styled from 'styled-components';

export const SettingsList = styled.ul`
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    &:nth-child(odd) {
      background-color: ${props => props.theme.pallet.primaryLighter};
    }

    button {
      border: none;
      background-color: ${props => props.theme.pallet.primary};
      line-height: 0;
      color: ${props => props.theme.pallet.white};
      margin-right: 10px;
    }
  }
`;

export const ResetToDefaults = styled.button`
  border: none;
  width: 100%;
  background-color: ${props => props.theme.pallet.primary};
  color: ${props => props.theme.pallet.white};
  padding: 10px 0;
  cursor: pointer;
`;