import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - 80px);

  .loading-text {
    position: absolute;
    left: calc(50% - 120px);
    right: calc(50% - 120px);
    top: calc(50% - 12px);
    text-align: center;
  }

  .animation-container {
    position: absolute;
    text-align: center;
    /* animation-name: pulse; */
    animation-duration: 2.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    overflow: hidden;

    svg {
      color: ${props => props.theme.pallet.primary};
      animation: spin 1.5s infinite linear;
    }

    p {
      margin-top: 0;
      font-size: 1.5em;
    }
  }

  @keyframes pulse {
    0% {transform: scale(1);}
    50% {transform: scale(1.05);}
    100% {transform: scale(1);}
  }

  @keyframes spin {
    100% {
      transform:rotate(360deg);
    }
  }
`;