import { IClause } from "../../types/IClause";
import clauseComparer from "../../../utilities/clauseComparer";
import { v4 as uuidv4 } from "uuid";

export default function (data, opts) {
  const getAliases = function (clause) {
    var t = [];

    t.push(clause);
    t.push(clause + "s");
    t.push(clause + "es");
    t.push(clause + "’s");
    t.push(clause + "’");
    t.push(clause + "'s");
    t.push(clause + "'");

    if (
      clause.substring(clause.length - 2, clause.length).toUpperCase() == "ES"
    )
      t.push(clause.substring(0, clause.length - 2));
    else if (
      clause.substring(clause.length - 1, clause.length).toUpperCase() == "S"
    )
      t.push(clause.substring(0, clause.length - 1));
    if (clause.substring(clause.length - 1, clause.length).toUpperCase() == "Y")
      t.push(clause.substring(0, clause.length - 1) + "ies");
    if (
      clause.substring(clause.length - 3, clause.length).toUpperCase() == "IES"
    )
      t.push(clause.substring(0, clause.length - 3) + "y");

    return t.map((r) => {
      return {
        word: r,
        id: uuidv4(),
        match: r,
      };
    });
  };

  const getClauseType = (clause) => {
    if (!clause.clause_type) {
      return "";
    } else return clause.clause_type.toUpperCase();
  };

  const clauses = data.clauses.map((c, i) => {
    return {
      id: uuidv4(),
      word: c.word,
      clauseType: getClauseType(c),
      title: c.word,
      children: data.graph.links
        .filter((r) => r.source === c.word)
        .map((r) => r.target)
        .concat([c.word]),
      definedDefinition: c.definitions
        .filter((r) => r.definition_type == "indexed_definitions")
        .map((r) => r.sentence),
      inlineDefinition: c.definitions
        .filter((r) => r.definition_type == "inline_definitions")
        .map((r) => r.sentence),
      tocDefinition: c.definitions
        .filter((r) => r.definition_type == "table_of_contents")
        .map((r) => r.sentence),
      referenceDefinition: c.definitions
        .filter((r) => r.definition_type == "reference")
        .map((r) => r.sentence),
      aliases: getAliases(c.word),
    };
  });

  return {
    clauses: clauses.sort((a, b) => clauseComparer(a, b)),
    level_0_nodes: data.level_0_nodes,
    graph: data.graph,
    raw: data.raw,
  };
}
