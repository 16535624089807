import { combineReducers } from "redux";

import terms from "./terms";
import font from "./font";
import user from "./user";
import regex from "./regex";
import application from "./application";

const rootReducer = combineReducers({
  terms,
  font,
  user,
  regex,
  application,
});

export default rootReducer;
