export const getAllClausesAndAlts = (
  terms,
  sections,
  exhibits,
  schedules,
  annexes,
  articles
) => {
  let allAlts = terms
    .map((r) => {
      const t = r.alternativeTerms.map((s) => {
        return {
          id: r.id,
          word: r.word,
          alternativeTerm: s.word,
          alternativeTermId: s.id,
        };
      });

      return t;
    })
    .concat(
      sections.map((r) => {
        const t = r.alternativeTerms.map((s) => {
          return {
            id: r.id,
            word: r.word,
            alternativeTerm: s.word,
            alternativeTermId: s.id,
          };
        });

        return t;
      })
    )
    .concat(
      exhibits.map((r) => {
        const t = r.alternativeTerms.map((s) => {
          return {
            id: r.id,
            word: r.word,
            alternativeTerm: s.word,
            alternativeTermId: s.id,
          };
        });

        return t;
      })
    )
    .concat(
      schedules.map((r) => {
        const t = r.alternativeTerms.map((s) => {
          return {
            id: r.id,
            word: r.word,
            alternativeTerm: s.word,
            alternativeTermId: s.id,
          };
        });

        return t;
      })
    )
    .concat(
      annexes.map((r) => {
        const t = r.alternativeTerms.map((s) => {
          return {
            id: r.id,
            word: r.word,
            alternativeTerm: s.word,
            alternativeTermId: s.id,
          };
        });

        return t;
      })
    )
    .concat(
      articles.map((r) => {
        const t = r.alternativeTerms.map((s) => {
          return {
            id: r.id,
            word: r.word,
            alternativeTerm: s.word,
            alternativeTermId: s.id,
          };
        });

        return t;
      })
    );

  if (allAlts.length > 0)
    allAlts = allAlts.reduce((prev, current) => [...prev, ...current]);

  const allClauses = terms
    .map((r) => {
      return {
        id: r.id,
        word: r.word,
        alternativeTerm: r.word,
        alternativeTermId: r.id,
      };
    })
    .concat(
      sections.map((r) => {
        return {
          id: r.id,
          word: r.word,
          alternativeTerm: r.word,
          alternativeTermId: r.id,
        };
      })
    )
    .concat(
      exhibits.map((r) => {
        return {
          id: r.id,
          word: r.word,
          alternativeTerm: r.word,
          alternativeTermId: r.id,
        };
      })
    )
    .concat(
      articles.map((r) => {
        return {
          id: r.id,
          word: r.word,
          alternativeTerm: r.word,
          alternativeTermId: r.id,
        };
      })
    )
    .concat(
      annexes.map((r) => {
        return {
          id: r.id,
          word: r.word,
          alternativeTerm: r.word,
          alternativeTermId: r.id,
        };
      })
    )
    .concat(
      schedules.map((r) => {
        return {
          id: r.id,
          word: r.word,
          alternativeTerm: r.word,
          alternativeTermId: r.id,
        };
      })
    );

  const allAltsAndClauses = allAlts
    .concat(allClauses)
    .sort((a, b) =>
      a.alternativeTerm.length > b.alternativeTerm.length ? -1 : 1
    );

  return allAltsAndClauses;
};

export const search = (clauses, text) => {
  let foundItem;

  foundItem = clauses.find(function (t) {
    return t.word.toUpperCase() == text.toUpperCase();
  });

  if (!foundItem) {
    foundItem = clauses.find(function (t) {
      if (!t.aliases) return false;
      const tidx = t.aliases.findIndex(function (r) {
        return r.word.toUpperCase() == text.toUpperCase();
      });
      return tidx > -1;
    });
  }

  return foundItem;
};
