import "core-js/stable";
import "regenerator-runtime/runtime";

import './index.css';


var ES6Promise = require("es6-promise");
ES6Promise.polyfill();

import { MemoryRouter } from "react-router-dom";

import "es6-object-assign";
import { AppContainer } from "react-hot-loader";
import * as React from "react";
import * as ReactDOM from "react-dom";
// import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { Provider } from "react-redux";

import App from "./screens/App";
import configureStore from "./configureStore";

import { Authenticator } from "../utilities/office.helpers.js";

// initializeIcons();

let isOfficeInitialized = false;
const title = "Spectacles";
export const store = configureStore();

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <MemoryRouter>
          <Component title={title} isOfficeInitialized={isOfficeInitialized} />
        </MemoryRouter>
      </Provider>
    </AppContainer>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.initialize = () => {
  // Clearing chat history
  // localStorage.removeItem("_message_history")

  if (Authenticator.isAuthDialog()) {
    return;
  }

  isOfficeInitialized = true;
  render(App);
};

/* Initial render showing a progress bar */
//render(App);

if ((module as any).hot) {
  (module as any).hot.accept("./screens/App", () => {
    const NextApp = require("./screens/App").default;
    render(NextApp);
  });
}
