import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  box-shadow: ${props => props.theme.ui.boxShadow};

  padding: 10px;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    color: ${props => props.theme.pallet.black};
    text-decoration: none;
    display: block;
    padding: 10px 20px;
    transition: all .3s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:nth-child(odd) {
      background-color: ${props => props.theme.pallet.primaryLighter};
    }
  }
`;