import styled, { ThemeProvider } from "styled-components";

export const ModalHeader = styled.div`
  background-color: ${(props) => props.theme.pallet.primary};
  padding: 20px;
  color: ${(props) => props.theme.pallet.white};

  h2 {
    font-size: 1.2rem;
    font-weight: 100 span {
      font-weight: 400;
      font-style: italic;
    }
  }
`;

export const ModalBody = styled.div`
  padding: 20px;
  padding-bottom: 10px;

  p {
    margin: 0;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;

  button {
    border: none;
    padding: 10px 20px;
  }

  .cancel {
    background-color: transparent;
    margin-right: 20px;
    color: #686868;
    font-weight: 100;

    &:hover {
      background-color: ${(props) => props.theme.pallet.primaryLighter};
    }
  }

  .upgrade {
    background-color: ${(props) => props.theme.pallet.primary};
    color: white;
    font-weight: 400;
  }
`;
