import {
  SET_SCROLL_TERM,
  ADD_SECONDARY_SCROLL_TERM,
  REMOVE_SECONDARY_SCROLL_TERM,
  CLEAR_SECONDARY_SCROLL_TERM,
  SET_IS_GRAPH_DIALOG,
} from "../constants/actionTypes";

export const initialState = {
  scrollTerm: undefined,
  secondaryScrollTerms: [],
  isGraphDialog: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SCROLL_TERM: {
      return {
        ...state,
        scrollTerm: action.payload,
      };
    }
    case ADD_SECONDARY_SCROLL_TERM: {
      return {
        ...state,
        secondaryScrollTerms: state.secondaryScrollTerms.concat(action.payload),
      };
    }
    case REMOVE_SECONDARY_SCROLL_TERM: {
      return {
        ...state,
        secondaryScrollTerms: removeItemFromArray(
          state.secondaryScrollTerms,
          action.payload
        ),
      };
    }
    case CLEAR_SECONDARY_SCROLL_TERM: {
      return {
        ...state,
        secondaryScrollTerms: [],
      };
    }
    case SET_IS_GRAPH_DIALOG: {
      return {
        ...state,
        isGraphDialog: action.payload,
      };
    }
    default:
      return state;
  }
}

function removeItemFromArray(array, payload) {
  const idx = array.lastIndexOf(payload);

  if (idx > -1) {
    array.splice(idx, 1);
  }

  return array;
}
