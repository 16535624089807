import React, { useEffect, useState } from "react";

import extractors from "../../extractors";
import server from "../../../utilities/server/index";

import { PrimaryButton, ContextualMenu } from "office-ui-fabric-react";
import { TextField } from "office-ui-fabric-react/lib/TextField";

import Redline from "./Redline";

import PropTypes from "prop-types";

import { Container, List } from "./styled";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { ProgressIndicator } from "office-ui-fabric-react/lib/ProgressIndicator";

import convertResponseData from "../../anaylsis/ai/convertResponseData";
import convertRecommendations from "../../anaylsis/ai/convertRecommendations";

import { Flex, Box } from "@rebass/grid";
import { search } from "../../../utilities/clauseHelpers";

import { Eye, EyeOff } from "react-feather";

import {
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react/lib/MessageBar";

/**
 * Primary UI component for user interaction
 */
const WriterSearch = (props) => {
  const { sample, word } = extractors;
  const { semanticSearch, generateDefinition, generateSection } = server;

  const { writerSearchState } = props;

  const [selectedText, setSelectedText] = useState();
  const [sectionTitle, setSectionTitle] = useState();
  const [context, setContext] = useState();
  const [results, setResults] = useState();
  const [banner, setBanner] = useState();
  const [resultsType, setResultsType] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (writerSearchState) {
      setSelectedText(writerSearchState.selectedText);
      setSectionTitle(writerSearchState.sectionTitle);
      setContext(writerSearchState.context);
      setResults(writerSearchState.results);
      setBanner(writerSearchState.banner);
      setResultsType(writerSearchState.resultsType);

      if (!writerSearchState.results) {
        if (writerSearchState.selectedText) {
          semanticSearch(writerSearchState.selectedText, 3, 1).then((r) => {
            setResultsType("semanticSearch");
            setResults([...r.data.data]);
            setLoading(false);
          });
        }
        if (writerSearchState.sectionTitle) {
          if (writerSearchState.initialSearchType === "definition") {
            generateDefinition(writerSearchState.sectionTitle, 3, 1).then(
              (r) => {
                setResultsType("generateDefinition");
                setResults([...r.data.data]);
                setLoading(false);
              }
            );
          } else {
            generateSection(writerSearchState.sectionTitle, [], 3, 1).then(
              (r) => {
                setResultsType("generateSection");
                setResults([...r.data.data]);
                setLoading(false);
              }
            );
          }
        }
      }
    } else {
      setLoading(false);
    }
  }, []);

  const doGenerateDefintion = async (e) => {
    setLoading(true);
    const r = await generateDefinition(sectionTitle, 3, 1);
    setResultsType("generateDefinition");
    setResults([...r.data.data]);
    setLoading(false);
  };

  const doGenerationSection = async (e) => {
    setLoading(true);
    const r = await generateSection(sectionTitle, [], 3, 1);
    setResultsType("generateSection");
    setResults([...r.data.data]);
    setLoading(false);
  };

  const doSemanticSearch = (e) => {
    setLoading(true);
    word.selectionAsText((text) => {
      setSelectedText(text);
      if (text) {
        semanticSearch(text, 3, 1).then((r) => {
          setResultsType("semanticSearch");
          setResults([...r.data.data]);
        });
      } else {
        setBanner("You must select text first to use this feature");
      }
      setLoading(false);
    });
  };

  const getClauseInDocument = (word) => {
    const clause = search(props.clauses, word);
    return clause;
  };

  const moveToChild = (child, clause) => {
    props.setWriterSearchState({
      selectedText,
      sectionTitle,
      results,
      banner,
      resultsType,
    });

    if (clause) {
      var html =
        clause.definedDefinition.join(" ") ??
        "" + clause.inlineDefinition.join(" ") ??
        "";
      var div = document.createElement("div");
      div.innerHTML = html;

      props.navigateToChild({
        writerSearchState: {
          selectedText: div.textContent || div.innerText,
        },
      });
    } else {
      props.navigateToChild({
        writerSearchState: {
          sectionTitle: child.word,
          initialSearchType:
            child.clause_type?.toUpperCase() === "TERM"
              ? "definition"
              : "section",
        },
      });
    }
  };

  const reset = () => {};

  const menuProps: IContextualMenuProps = {
    directionalHintFixed: false,
    items: [
      {
        key: "getDefinitions",
        text: "Get Terms",
        iconProps: { iconName: "BuildDefinition" },
        onClick: doGenerateDefintion,
      },
      {
        key: "getSections",
        text: "Get Sections",
        iconProps: { iconName: "Library" },
        onClick: doGenerationSection,
      },
    ],
  };

  return (
    <>
      <>
        <span>
          Enter <b>{"{section_title}"}</b> or <b>{"{term}"}</b> and a{" "}
          <b>{"{document type}"}</b> for context{" "}
        </span>
        <TextField
          onChange={(e) => setSectionTitle(e.target.value)}
          value={sectionTitle}
        />
        <PrimaryButton
          text="Search"
          splitButtonAriaLabel="Search..."
          aria-roledescription="split button"
          menuProps={menuProps}
          menuAs={(props) => <ContextualMenu {...props} />}
          persistMenu={false}
        />
      </>
      <h3>Or </h3>
      <>
        <PrimaryButton text="Compare Search" onClick={doSemanticSearch} />
      </>
      {loading && <Spinner size={SpinnerSize.large} />}

      {!loading && (
        <>
          {selectedText && <p>{selectedText}</p>}

          {results &&
            results.map((result, i) => {
              return (
                <Container key={i}>
                  <ProgressIndicator
                    label={"Score: " + result.score}
                    percentComplete={(result.score ?? 0) / 100}
                  />
                  <PrimaryButton
                    text="Insert"
                    onClick={() => {
                      word.insertText(result.value, true, () => {
                        updateRecommendations(result);
                      });
                    }}
                  />
                  <h4>Text</h4>
                  <p>{result.value}</p>
                  {resultsType === "semanticSearch" && (
                    <>
                      <h4>Redline</h4>
                      <p>
                        <Redline
                          originalText={selectedText}
                          suggestedText={result.value}
                        ></Redline>
                      </p>
                    </>
                  )}
                  {result?.children && result.children.length > 0 && (
                    <>
                      <h4>Children</h4>
                      <List>
                        {result.children.map((child, j) => {
                          const clauseInDocument = getClauseInDocument(
                            child.word
                          );
                          return (
                            <div key={j}>
                              <li>
                                <Flex>
                                  <Box width={1 / 6}>
                                    {clauseInDocument ? <Eye /> : <EyeOff />}
                                  </Box>
                                  <Box width={3 / 6}>{child.word}</Box>
                                  <Box width={2 / 6}>
                                    <PrimaryButton
                                      text={
                                        clauseInDocument ? "Compare" : "Prompt"
                                      }
                                      onClick={() =>
                                        moveToChild(child, clauseInDocument)
                                      }
                                    />
                                  </Box>
                                </Flex>
                              </li>
                              <div style={{ display: "none" }}>
                                {child.indexed_definitions?.map((r) => r.value)}

                                {child.inline_definitions?.map((r) => r.value)}
                              </div>
                            </div>
                          );
                        })}
                      </List>
                    </>
                  )}
                </Container>
              );
            })}
        </>
      )}
    </>
  );
};

WriterSearch.propTypes = {};

WriterSearch.defaultProps = {};

export default WriterSearch;
