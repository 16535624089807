import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

// import Particles from 'react-particles-js';
import { getDomainHint } from "../../../utilities/server";

import {
  Button,
  MessageBar,
  MessageBarType,
  Image,
  IImageProps,
  TextField,
  PrimaryButton,
  DefaultButton,
  Stack,
} from "office-ui-fabric-react";

import DefaultHeader from "../../components/DefaultHeader";

const Container = styled.div`
  margin: auto;
  width: calc(100vw - 30px);
  height: calc(100vh - 200px);
`;

export interface ILoginScreenProps {
  login: (provider: string) => {};
  navToSpectacles: () => void;
  errorMessage: string;
  cancelLogin: () => void;
}

export interface ILoginScreenState {
  selectedFont: React.ReactText;
  username: string;
  password: string;
  loginErrors: string;
}

export default class Login extends React.Component<
  ILoginScreenProps,
  ILoginScreenState
> {
  render() {
    const {
      login,
      navToSpectacles,
      errorMessage,
      loggingIn,
      loginWithSpectacles,
    } = this.props;

    function ErrorBox(props) {
      const errorMessage = props.errorMessage;

      if (errorMessage) {
        return (
          <MessageBar messageBarType={MessageBarType.error}>
            {errorMessage}{" "}
          </MessageBar>
        );
      } else {
        return <div></div>;
      }
    }

    function Display(props) {
      const usernameTextField = useRef();
      const passwordTextField = useRef();

      const [obtainingDomainHint, setObtainingDomainHint] = useState(false);

      const [showDomainHintError, setShowDomainHintError] = useState(false);
      const [showDomainHintSuccess, setShowDomainHintSuccess] = useState(false);

      const oktaLogin = () => {
        getDomainHint(
          usernameTextField.current.value,
          (resp) => {
            if (resp.data) {
              setShowDomainHintError(false);
              setShowDomainHintSuccess(true);
              props.login("Okta", {
                domain_hint: resp.data,
                login_hint: usernameTextField.current.value,
              });
            } else {
              setShowDomainHintError(true);
              setShowDomainHintSuccess(false);
            }
          },
          (error) => {
            console.log(error);
            setShowDomainHintError(true);
            setShowDomainHintSuccess(false);
          }
        );
      };

      if (props.loggingIn) {
        return (
          <div>
            <TextField componentRef={usernameTextField} label="Username" />
            <TextField
              componentRef={passwordTextField}
              label="Password"
              type="password"
              canRevealPassword
            />
            <br />
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                text="Login"
                onClick={() =>
                  props.loginWithSpectacles(
                    usernameTextField.current.value,
                    passwordTextField.current.value
                  )
                }
              />
              <DefaultButton text="Back" onClick={() => props.cancelLogin()} />
            </Stack>
          </div>
        );
      }
      if (obtainingDomainHint) {
        return (
          <div>
            <div style={{ textAlign: "center" }}>
              <img
                src="../../../assets/Logo_Okta_Blue_RGB.png"
                style={{ width: "80%", marginTop: "30px" }}
              />
            </div>

            <h3>Please type in your email address</h3>

            <TextField componentRef={usernameTextField} label="Email Address" />
            <br />
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton text="Login" onClick={() => oktaLogin()} />
              <DefaultButton text="Back" onClick={() => props.cancelLogin()} />
            </Stack>
            <br />
            {showDomainHintError && (
              <MessageBar messageBarType={MessageBarType.error}>
                That email address is not associated with an Okta account
              </MessageBar>
            )}
            {showDomainHintSuccess && (
              <MessageBar messageBarType={MessageBarType.success}>
                Please wait while we authorize your license
              </MessageBar>
            )}
          </div>
        );
      } else {
        return (
          <div>
            <p>
              Ask Spectacles Any Question About Your Document or To Suggest New
              Text
            </p>

            <h4> Please Login with your account.</h4>

            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <img
                style={{ cursor: "pointer", width: 250 }}
                onClick={props.login.bind(this, "Microsoft")}
                src="../../../assets/Microsoft - Login.png"
              ></img>

              <img
                style={{ cursor: "pointer", width: 250 }}
                onClick={props.login.bind(this, "Google")}
                src="../../../assets/Google - Login.png"
              ></img>

              <img
                style={{ cursor: "pointer", width: 250 }}
                onClick={props.login.bind(this, "Clio")}
                src="../../../assets/Clio - Login.png"
              ></img>

              <img
                style={{ cursor: "pointer", width: 250 }}
                onClick={() => setObtainingDomainHint(true)}
                src="../../../assets/Okta - Login.png"
              ></img>

              <img
                style={{ cursor: "pointer", width: 250 }}
                onClick={props.login.bind(this, "Spectacles")}
                src="../../../assets/Spectacles - Login.png"
              ></img>
            </div>
          </div>
        );
      }
    }

    return (
      <div>
        <ErrorBox errorMessage={errorMessage}></ErrorBox>

        <DefaultHeader></DefaultHeader>

        {/* <Particles
          height="calc(100vh - 150px)"
          width="100vw"
          style={{
            position: 'fixed',
            zIndex: '-1000000'
          }}
          params={{
            "particles": {
              "color": {
                "value": '#0078d4',
              },
              "number": {
                'value': 75,
              },
              "line_linked": {
                "color": "#0078d4",
                "opacity": .2,
              },
              "move": {
                "speed": 2,
              }
            },
          }}
        /> */}

        <Container>
          <Display
            loggingIn={this.props.loggingIn}
            login={this.props.login}
            loginWithSpectacles={this.props.loginWithSpectacles}
            cancelLogin={this.props.cancelLogin}
          ></Display>

          <h4>Don't have a Spectacles subscription yet?</h4>
          <img
            src="../../../assets/spectacles-logo.png"
            style={{ width: "10%" }}
          ></img>
          <Button onClick={navToSpectacles}>Get It Here</Button>

          <br />
        </Container>
      </div>
    );
  }
}
