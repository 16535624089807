import { modelType } from "./types";

export const SELECTED_TEXT_CHARACTER_LIMIT = 10000;

export const providers = [
  {
    name: "OpenAI",
    value: "openai",
    providerId: 1,
  },
  {
    name: "Anthropic",
    value: "anthropic",
    providerId: 2,
  },
  {
    name: "Together AI",
    value: "togetherai",
    providerId: 3,
  },  
  {
    name: "Mistral AI",
    value: "mistralai",
    providerId: 4,
  },
];

export const openAiModels: modelType[] = [
  {
    name: "GPT-4o",
    value: "gpt-4o",
    modelId: 1,
  },
  {
    name: "GPT-4o mini",
    value: "gpt-4o-mini",
    modelId: 2,
  },
];

export const togetherModels: modelType[] = [
  {
    name: "Llama 3.1 8B Instruct Turbo",
    value: "meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo",
    modelId: 7,
  },
  {
    name: "Llama 3.1 70B Instruct Turbo",
    value: "meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo",
    modelId: 8,
  },
];

export const anthropicModels: modelType[] = [
  {
    name: "Claude 3 Opus",
    value: "claude-3-opus-20240229",
    modelId: 5,
  },
  {
    name: "Claude 3 Sonnet",
    value: "claude-3-sonnet-20240229",
    modelId: 6,
  },
];

export const mistralModels: modelType[] = [
  {
    name: "Mistral Large",
    value: "mistral-large-latest-2407",
    modelId: 3,
  },
  {
    name: "Pixtral 12B",
    value: "pixtral-12b-2409",
    modelId: 4,
  },
];