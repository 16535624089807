import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  padding: 4px;
  box-shadow: ${(props) => props.theme.ui.boxShadowVertical};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 250px;
  width: 100%;
  margin: 0 auto;

  button {
    background-color: ${(props) => props.theme.pallet.primary};
    color: ${(props) => props.theme.pallet.white};
    border: none;
    line-height: 0;
    padding: 10px;
  }
`;
