import {
  INCREASE_FONT_SIZE,
  DECREASE_FONT_SIZE,
  CHANAGE_FONT_FAMILY,
  RESET_TO_DEFAULT,
} from '../constants/actionTypes';

export type increaseFontSizeType = (payload: number) => void;
export const increaseFontSize = (payload) => ({
  type: INCREASE_FONT_SIZE,
  payload
});

export type decreaseFontSizeType = (payload: number) => void;
export const decreaseFontSize = (payload) => ({
  type: DECREASE_FONT_SIZE,
  payload
});

export type changeFontFamilyType = (payload: string) => void;
export const changeFontFamily = (payload) => ({
  type: CHANAGE_FONT_FAMILY,
  payload
});

export type resetToDefaultType = () => void;
export const resetToDefault = () => ({
  type: RESET_TO_DEFAULT, 
});
