import React, { useEffect, useState } from "react";

import { List } from "./styled";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import ClauseListItem from "../ClauseListItem";

/**
 * Primary UI component for user interaction
 */
const ClauseNavigatorList = (props) => {
  const { clauses } = props;

  const [showTerms, setShowTerms] = useState([]);

  const toggleClause = (clause) => {
    var tempShowTerms = [...showTerms];

    var idx = tempShowTerms.indexOf(clause);

    if (idx > -1) {
      tempShowTerms.splice(idx, 1);
    } else {
      tempShowTerms.push(clause);
    }

    setShowTerms(tempShowTerms);
  };

  return (
    <>
      {(!clauses || clauses.length == 0) && (
        <>
          <p>There are no child clauses for this term</p>
        </>
      )}

      {clauses && clauses.length > 0 && (
        <>
          <List>
            {clauses.map((clause, i) => {
              return (
                clause.id != props.parentId && (
                  <li key={i}>
                    <div
                      style={{ width: "100%", display: "flex", height: "100%" }}
                      onClick={() => toggleClause(clause)}
                    >
                      {clause.word}
                    </div>

                    <ClauseListItem
                      show={showTerms.indexOf(clause) > -1}
                      clause={clause}
                      analysis={props.analysis}
                    ></ClauseListItem>
                  </li>
                )
              );
            })}
          </List>
        </>
      )}
    </>
  );
};

ClauseNavigatorList.propTypes = {
  clauses: PropTypes.array,
  analysis: PropTypes.object,
  parentId: PropTypes.string,
};

ClauseNavigatorList.defaultProps = {
  clauses: [],
  analysis: undefined,
};

export default ClauseNavigatorList;
