import * as React from "react";
import { connect } from "react-redux";
import {
  Link,
  RouteComponentProps,
  withRouter,
  Redirect,
} from "react-router-dom";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import styled from "styled-components";

import { Folder, Download } from "react-feather";

import { Section, Container } from "../../components/ui/Grid";

import { IUser } from "../../interfaces/IUserInterface";

import { getClioDocs, getClioDoc } from "../../../utilities/server";

import TermsLoading from "../../components/TermsLoading";
import { TermsList, ClioContainer } from "./styled";

import {
  Breadcrumb,
  IBreadcrumbItem,
} from "office-ui-fabric-react/lib/Breadcrumb";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faFileWord,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

interface IClioScreenProps extends RouteComponentProps<any> {
  user: IUser;
  environment_conf: any;
}

class ClioScreen extends React.Component<IClioScreenProps, {}> {
  private container: React.RefObject<HTMLDivElement>;

  constructor(props: IClioScreenProps) {
    super(props);

    this.container = React.createRef();

    this.state = {
      items: [],
      breadcrumbs: [],
      error: "",
      loadingStatus: "loading",
    };
  }

  componentDidMount() {
    this.getItems(-1);

    let breadcrumbs = this.state.breadcrumbs;
    breadcrumbs.push({
      text: "Home",
      key: -1,
      folder: {
        id: -1,
        name: "Home",
      },
      onClick: this.handleBreadcrumbClick,
    });

    this.setState({ breadcrumbs: breadcrumbs });
  }

  getItems = (parentId) => {
    getClioDocs(
      this.props.user.accessToken,
      parentId,
      this.props.environment_conf,
      this.handleGetDocs
    );
  };

  getDocument = (documentId) => {
    getClioDoc(
      this.props.user.accessToken,
      documentId,
      this.props.environment_conf,
      this.handleGetDoc
    );
  };

  handleGetDocs = (httpResponse) => {
    if (httpResponse.status == 200) {
      this.setState({
        items: httpResponse.data.sort((a, b) =>
          a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
        ),
        loadingStatus: "done",
      });
    } else {
      this.setState({ error: httpResponse.statusText, loadingStatus: "done" });
    }
  };

  handleGetDoc = (httpResponse) => {
    if (httpResponse.status == 200) {
      window.open(httpResponse.data.downloadUrl);
    } else {
      this.setState({ error: httpResponse.statusText, loadingStatus: "done" });
    }
  };

  displayItem = (item, index) => {
    if (item.type == "Folder") return this.displayFolder(item, index);
    else return this.displayDocument(item, index);
  };

  displayFolder = (folder, index) => {
    return (
      <li key={index} onClick={(e) => this.handleFolderClick(folder)}>
        <FontAwesomeIcon
          icon={faFolder}
          size="lg"
          style={{ paddingRight: "5px" }}
        />
        {folder.name}
      </li>
    );
  };

  displayDocument = (document, index) => {
    return (
      <li key={index} onClick={(e) => this.getDocument(document.id)}>
        <FontAwesomeIcon
          icon={faFileWord}
          size="lg"
          style={{ paddingRight: "5px" }}
        />
        {document.name}
      </li>
    );
  };

  displayItems = () => {
    return (
      <TermsList>
        {this.state.items.map((item, index) => this.displayItem(item, index))}
      </TermsList>
    );
  };

  displayBody = () => {
    if (this.state.loadingStatus == "loading") {
      return <TermsLoading text="Loading Clio Docs" />;
    } else if (this.state.error) {
      return <div>{this.state.error}</div>;
    } else {
      return (
        <div>
          <div
            style={{
              paddingBottom: "10px",
              paddingTop: "3px",
              backgroundColor: "#0078d4",
              verticalAlign: "center",
              position: "relative",
              height: "50px",
            }}
          >
            <div
              style={{
                display: "inline",
                width: "10%",
                padding: "10px",
                cursor: "pointer",
                position: "absolute",
                left: "0%",
              }}
              onClick={() => this.handleBackClick()}
            >
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                size="lg"
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
            <div
              style={{
                width: "90%",
                position: "absolute",
                left: "13%",
                top: "-20%",
              }}
            >
              <Breadcrumb items={this.state.breadcrumbs} />
            </div>
          </div>
          {this.displayItems()}
        </div>
      );
    }
  };

  addBreadcrumb = (folder) => {
    var breadcrumbs = this.state.breadcrumbs;

    breadcrumbs.push({
      text: folder.name,
      key: folder.id,
      folder: folder,
      onClick: this.handleBreadcrumbClick,
    });

    this.setState({ breadcrumbs: breadcrumbs });
  };

  handleBackClick = () => {
    const idx = this.state.breadcrumbs.length - 2;
    if (idx < 0) return;

    var bc = this.state.breadcrumbs[idx];

    return this.handleBreadcrumbClick(null, bc);
  };

  handleBreadcrumbClick = (e, item) => {
    var folder = item.folder;
    var breadcrumbs = this.state.breadcrumbs;
    var self = this;
    var idx = breadcrumbs.findIndex(
      (element) => element.folder.name == folder.name
    );

    breadcrumbs = breadcrumbs.slice(
      0,
      breadcrumbs.findIndex((element) => element.folder.name == folder.name)
    );

    this.setState({ breadcrumbs: breadcrumbs }, () => {
      self.handleFolderClick(folder);
    });
  };

  handleFolderClick = (folder) => {
    this.getItems(folder.id);
    this.addBreadcrumb(folder);
  };

  render() {
    if (this.props.user.provider == "Clio") {
      return (
        <Section ref={this.container}>
          <ClioContainer>
            <img
              style={{ width: "100%" }}
              src="../../../assets/Clio Documents Logo.png"
            />
            {this.displayBody()}
          </ClioContainer>
        </Section>
      );
    } else {
      return (
        <Section ref={this.container}>
          <img
            style={{ width: 250 }}
            src="../../../assets/Clio Documents Logo.png"
          />
          <p>
            You are not logged in with a Clio account. Please log out then log
            in with your Clio account.
          </p>
        </Section>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(ClioScreen);
