import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import AiAnalyzer from "./AiAnalyzer/";
import ClauseNavigatorComtainer from "./ClauseNavigatorContainer/";

interface IExperimentalScreenProps extends RouteComponentProps<any> {
  bodyHtml: string;
}

class ExperimentalScreen extends React.Component<IExperimentalScreenProps, {}> {
  private container: React.RefObject<HTMLDivElement>;

  constructor(props: IClioScreenProps) {
    super(props);
  }

  render() {
    // return <AiAnalyzer bodyHtml={this.props.bodyHtml}></AiAnalyzer>;
    return (
      <ClauseNavigatorComtainer
        bodyHtml={this.props.bodyHtml}
      ></ClauseNavigatorComtainer>
    );
  }
}

const mapStateToProps = (state) => ({
  bodyHtml: state.terms.analysis.raw,
});

export default connect(mapStateToProps)(ExperimentalScreen);
