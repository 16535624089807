import React, { useEffect, useState } from "react";
import patienceDiff from "../../../../utilities/patienceDiff";
import { RedText, GreenText, Text } from "./styled";

type IProps = {
  originalText: string;
  suggestedText: string;
};
const Redline = ({ originalText, suggestedText }: IProps) => {
  const [model, setModel] = useState([]);

  useEffect(() => {
    const results = patienceDiff(
      originalText.split(" "),
      suggestedText.split(" ")
    );

    let mode = "";
    let workingObj: { words: string[]; mode: string };
    let newModal = [];

    for (var i = 0; i < results.lines.length; i++) {
      let result = results.lines[i];
      let nMode;

      if (result.aIndex < 0 && result.bIndex >= 0) {
        nMode = "new";
      } else if (result.aIndex >= 0 && result.bIndex >= 0) {
        nMode = "both";
      } else {
        nMode = "old";
      }

      if (mode != nMode) {
        workingObj = {
          words: [result.line],
          mode: nMode,
        };

        newModal.push(workingObj);
        mode = nMode;
      } else {
        workingObj.words.push(result.line);
      }
    }

    setModel(newModal);
  }, []);

  if (!model.length) return <p>no resultes</p>;

  return (
    <p>
      {model.map((item, i) => {
        switch (item.mode) {
          case "new":
            return <GreenText key={i}>{item.words.join(" ")}</GreenText>;
          case "old":
            return <RedText key={i}>{item.words.join(" ")}</RedText>;
          case "both":
            return <Text key={i}>{item.words.join(" ")}</Text>;
          default:
            return <Text key={i}>{item.words.join(" ")}</Text>;
        }
      })}
    </p>
  );
};

export default Redline;
