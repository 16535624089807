import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  SET_TERMS_START,
  SET_TERMS_SUCCESS,
  SET_TERMS_SEARCHING_DEFINED,
  SET_TERMS_MERGING,
  SET_CURRENT_TERM_OFFSET_TOP,
  SET_TOC_SUCCESS,
  SET_ANALYSIS,
  ADD_RECOMMENDATIONS,
  MARK_RECOMMENDATION_AS_INSERTED
} from "../constants/actionTypes";
import { ISetTermsSuccessAction } from "../interfaces/IReducerInterfaces";
import { ITermsList, ITableOfContents } from "../interfaces/ITermsInterface";
import analyzeDocument from "../anaylsis/regex/analyzeDocument";

type setTermsStartedType = () => void;
const setTermsStarted: setTermsStartedType = () => ({
  type: SET_TERMS_START,
});

type setTermsFinishedType = (
  payload: ISetTermsSuccessAction
) => ISetTermsSuccessAction;
const setTermsFinished = (payload) => ({
  type: SET_TERMS_SUCCESS,
  payload,
});

type setTocFinishedType = (payload: ITableOfContents) => ISetTermsSuccessAction;
const setTocFinished = (payload) => ({
  type: SET_TOC_SUCCESS,
  payload,
});

type setLoadingStatusDefinedType = () => void;
const setLoadingStatusDefined: setLoadingStatusDefinedType = () => ({
  type: SET_TERMS_SEARCHING_DEFINED,
});

type setLoadingStatusMergingType = () => void;
const setLoadingStatusMerging: setLoadingStatusMergingType = () => ({
  type: SET_TERMS_MERGING,
});

export type setCurrentTermOffsetTopType = (payload: number) => void;
export const setCurrentTermOffsetTop = (payload: number) => ({
  type: SET_CURRENT_TERM_OFFSET_TOP,
  payload,
});

export type setTermsType = () => ThunkAction<
  Promise<ITermsList>,
  null,
  null,
  null
>;
export const setTerms = (algo) => async (dispatch) => {
  dispatch(setTermsStarted());

  dispatch(setLoadingStatusDefined());

  Word.run(async (context) => {
    var body = context.document.body;
    var bodyHtml = body.getHtml();

    context.sync().then(function () {
      var html = bodyHtml.value;

      const results = analyzeDocument(html);

      dispatch(
        setTocFinished({
          sections: results.sections,
          exhibits: results.exhibits,
          schedules: results.schedules,
          annexes: results.annexes,
          articles: results.articles,
          bodyHtml: results.html,
        })
      );
      dispatch(setTermsFinished(results.terms));
    });
  });
};

export type setAnalysisType = (payload: any) => void;
export const setAnalysis = (payload: any) => ({
  type: SET_ANALYSIS,
  payload,
});

export type addRecommendationsType = (payload: any) => void;
export const addRecommendations = (payload: any) => ({
  type: ADD_RECOMMENDATIONS,
  payload,
});

export type markRecommendationAsInsertedType = (payload: any) => void;
export const markRecommendationAsInserted = (payload: any) => ({
  type: MARK_RECOMMENDATION_AS_INSERTED,
  payload,
});

