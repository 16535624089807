import React from "react";
import styled from "styled-components";

export const TermLinkSpan = styled.span`
  padding: 2px 8px;
  background-color: ${(props) => props.theme.pallet.primaryLight};
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  line-height: 1.5;

  &:hover {
    background-color: ${(props) => props.theme.pallet.primary};
    color: ${(props) => props.theme.pallet.white};
  }
`;

const TermLink = ({ termId, children, setCurrentTerm }) => {
  const handleClick = () => {
    setCurrentTerm(termId);
  };
  return <TermLinkSpan onClick={() => handleClick()}>{children}</TermLinkSpan>;
};

export default TermLink;
