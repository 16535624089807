import React, { useEffect, useState } from "react";

import { ClauseContainer } from "./styled";
import { Section } from "../ui/Grid";
import JsxParser from "react-jsx-parser";

import UpgradeModal from "./UpgradeModal";
import ClauseLink from "./ClauseLink";

import PropTypes from "prop-types";

import { IClause } from "../../types/IClause";

import ClauseDefinition from "./ClauseDefinition";

/**
 * Primary UI component for user interaction
 */
const Clause = (props) => {
  const [showModal, setShowModal] = useState(false);

  const handleClauseLinkClick = (word) => {
    if (props.allowNavigateToClause) {
      if (props.addSecondaryScrollTerm) {
        props.addSecondaryScrollTerm(props.clause.word);
      }
      props.navigateToClause(word);
    } else {
      setShowModal(true);
    }
  };

  const checkForScrollItem = () => {
    if (!props.secondaryScrollTerms || props.secondaryScrollTerms.length == 0) {
      scrollToTop();
      return;
    }

    const scrollTerm =
      props.secondaryScrollTerms[props.secondaryScrollTerms.length - 1];

    if (!scrollTerm) {
      scrollToTop();
      return;
    }

    if (scrollTerm === props.clause.word) {
      scrollToTop();
      return;
    }

    const element = document.getElementById(scrollTerm);

    if (element) {
      element.scrollIntoView();
      props.removeSecondaryScrollTerm(scrollTerm);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    var header = document.getElementById("term-header");
    if (header) header.scrollIntoView();
  };

  useEffect(() => {
    checkForScrollItem();
  }, [props.clause]);

  return (
    <Section>
      <h2 id="term-header">{props.clause.word}</h2>
      <ClauseContainer>
        <ClauseDefinition
          {...props}
          handleClauseLinkClick={handleClauseLinkClick}
        />
      </ClauseContainer>

      <UpgradeModal show={showModal} closeModal={() => setShowModal(false)} />
    </Section>
  );
};

Clause.propTypes = {
  clause: PropTypes.object,
  clauses: PropTypes.array,
  allowNavigateToClause: PropTypes.bool,
  navigateToClause: PropTypes.func,
};

Clause.defaultProps = {};

export default Clause;
