import React, { useEffect } from "react";

import { connect } from "react-redux";
import { Pivot, PivotItem, PivotLinkSize } from "@fluentui/react/lib/Pivot";

import { Section, Container } from "../../components/ui/Grid";

import ClauseNavigatorList from "../../components/ClauseNavigatorList";

import PropTypes from "prop-types";

/**
 * Primary UI component for user interaction
 */
const Clauses = (props) => {
  const currentSection =
    props.history?.location?.state?.currentSection ?? "terms";
  const currentClause = props.history?.location?.state?.clause ?? "";
  var childrenClauses;
  var currentDefinition;

  const navigateToScreen = (screen, args) => {
    props.history.push(screen, args);
  };

  return (
    <Section>
      <Pivot
        overflowBehavior="menu"
        overflowAriaLabel="more items"
        //linkSize={PivotLinkSize.normal}
        selectedKey={currentSection}
        onLinkClick={(item) =>
          navigateToScreen(`/`, {
            currentSection: item.props.itemKey,
          })
        }
      >
        <PivotItem
          style={{
            overflow: "auto",
          }}
          headerText="Terms"
          itemKey="terms"
          headerButtonProps={{
            "data-order": 1,
            "data-title": "Terms",
          }}
        >
          {currentSection == "terms" && (
            <ClauseNavigatorList
              clauses={props.analysis.clauses.filter(
                (r) => r.clauseType.toUpperCase() === "TERM"
              )}
              analysis={props.analysis}
            ></ClauseNavigatorList>
          )}
        </PivotItem>

        <PivotItem
          style={{
            height: "calc(100vh - 40vh)",
            overflow: "auto",
          }}
          headerText="Sections"
          itemKey="sections"
          headerButtonProps={{
            "data-order": 2,
            "data-title": "Sections",
          }}
        >
          {currentSection == "sections" && (
            <ClauseNavigatorList
              clauses={props.analysis.clauses.filter(
                (r) => r.clauseType.toUpperCase() == "SECTION"
              )}
              analysis={props.analysis}
            ></ClauseNavigatorList>
          )}
        </PivotItem>

        <PivotItem
          style={{
            height: "calc(100vh - 40vh)",
            overflow: "auto",
          }}
          headerText="Articles"
          itemKey="articles"
          headerButtonProps={{
            "data-order": 2,
            "data-title": "Articles",
          }}
        >
          {currentSection == "articles" && (
            <ClauseNavigatorList
              clauses={props.analysis.clauses.filter(
                (r) => r.clauseType.toUpperCase() == "ARTICLE"
              )}
              analysis={props.analysis}
            ></ClauseNavigatorList>
          )}
        </PivotItem>

        <PivotItem
          style={{
            height: "calc(100vh - 40vh)",
            overflow: "auto",
          }}
          headerText="Exhibits"
          itemKey="exhibits"
          headerButtonProps={{
            "data-order": 2,
            "data-title": "Exhibits",
          }}
        >
          {currentSection == "exhibits" && (
            <ClauseNavigatorList
              clauses={props.analysis.clauses.filter(
                (r) => r.clauseType.toUpperCase() == "EXHIBIT"
              )}
              analysis={props.analysis}
            ></ClauseNavigatorList>
          )}
        </PivotItem>

        <PivotItem
          style={{
            height: "calc(100vh - 40vh)",
            overflow: "auto",
          }}
          headerText="Schedules"
          itemKey="schedules"
          headerButtonProps={{
            "data-order": 2,
            "data-title": "Schedules",
          }}
        >
          {currentSection == "schedules" && (
            <ClauseNavigatorList
              clauses={props.analysis.clauses.filter(
                (r) => r.clauseType.toUpperCase() == "SCHEDULE"
              )}
              analysis={props.analysis}
            ></ClauseNavigatorList>
          )}
        </PivotItem>

        <PivotItem
          style={{
            height: "calc(100vh - 40vh)",
            overflow: "auto",
          }}
          headerText="Annexes"
          itemKey="annexes"
          headerButtonProps={{
            "data-order": 2,
            "data-title": "Annexes",
          }}
        >
          {currentSection == "annexes" && (
            <ClauseNavigatorList
              clauses={props.analysis.clauses.filter(
                (r) => r.clauseType.toUpperCase() == "ANNEX"
              )}
              analysis={props.analysis}
            ></ClauseNavigatorList>
          )}
        </PivotItem>
      </Pivot>
    </Section>
  );
};

Clauses.propTypes = {
  analysis: PropTypes.object,
};

Clauses.defaultProps = {};

function mapStateToProps(state) {
  return {
    analysis: state.terms.analysis,
  };
}

export default connect(mapStateToProps, null)(Clauses);
