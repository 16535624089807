import React, { useState, useEffect } from "react";

import {
  Pivot,
  PivotItem,
  PivotLinkSize,
} from "office-ui-fabric-react/lib/Pivot";

import WriterSearch from "../../components/WriterSearch";

import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Section, Container } from "../../components/ui/Grid";

import {
  addRecommendations,
  addRecommendationsType,
  markRecommendationAsInserted,
  markRecommendationAsInsertedType,
} from "../../actions/terms";

const Writer = (props) => {
  const searchType = props.history.location.state?.search;
  const tab = searchType ? searchType : props.history.location.state?.tab;

  const setWriterSearchState = (writerSearchState) => {
    props.history.location.state = { writerSearchState };
  };

  const navigateToChild = (writerSearchState) => {
    props.history.push(`/writer`, writerSearchState);
  };

  return (
    <Section>
      <WriterSearch
        clauses={props.analysis.clauses}
        setWriterSearchState={setWriterSearchState}
        writerSearchState={props.history.location.state?.writerSearchState}
        navigateToChild={navigateToChild}
      />
    </Section>
  );
};

Writer.propTypes = {};

Writer.defaultProps = {};

const mapStateToProps = (state) => ({
  recommendations: state.terms.recommendations,
  analysis: state.terms.analysis,
});

const mapDispatchToProps = (dispatch) => ({
  addRecommendations: (recommendations: any) =>
    dispatch(addRecommendations(recommendations)),
  markRecommendationAsInserted: (recommendation: string) =>
    dispatch(markRecommendationAsInserted(recommendation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Writer);
