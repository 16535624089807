import { environment_conf as conf } from "../environment";
import * as live from "./live";
import * as sample from "./sample";

export default {
  getDomainHint: conf.useStub ? sample.getDomainHint : live.getDomainHint,
  getLicense: conf.useStub ? sample.getLicense : live.getLicense,
  getClioDocs: conf.useStub ? sample.getClioDocs : live.getClioDocs,
  getClioDoc: conf.useStub ? sample.getClioDoc : live.getClioDoc,
  getAnalysis: conf.useStub ? sample.getAnalysis : live.getAnalysis,
  semanticSearch: conf.useStub ? sample.semanticSearch : live.semanticSearch,
  generateDefinition: conf.useStub ? sample.generateDefinition : live.generateDefinition,
  generateSection: conf.useStub ? sample.generateSection : live.generateSection,
};
