const document = (callback) => {
  Word.run(async (context) => {
    var body = context.document.body;
    var bodyHtml = body.getHtml();

    context.sync().then(function () {
      var html = bodyHtml.value;
      if (callback) callback(html);
    });
  });
};

const selection = (callback) => {
  return Word.run(async (context) => {
    const range = context.document.getSelection();
    range.load("text");
    await context.sync();

    if (range.text.length) {
      const selectedText = range.text.trim();
      callback(selectedText);
    } else {
      callback(null);
    }
  });
};

const selectionAsText = (callback) => {
  return Word.run(async (context) => {
    const range = context.document.getSelection();
    range.load("text");
    await context.sync();

    if (range.text.length) {
      const selectedText = range.text.trim();
      callback(selectedText);
    } else {
      callback(null);
    }
  });
};

const insertText = (text, withReplace, callback) => {
  return Word.run(async (context) => {
    var selectionRange = context.document.getSelection();

    if (withReplace) {
      selectionRange.delete();
    } else {
      //text = " " + text;
    }

    selectionRange.insertText(text, Word.InsertLocation.end);
    await context.sync();

    if (callback) callback();
  });
};

export default {
  document,
  selection,
  selectionAsText,
  insertText,
};
