import styled from 'styled-components';

export const TermsList = styled.ul`
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    color: ${props => props.theme.pallet.black};
    text-decoration: none;
    display: block;
    padding: 10px 20px;
    cursor: pointer;
    transition: all .3s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:nth-child(odd) {
      background-color: ${props => props.theme.pallet.primaryLighter};
    }

    &:hover {
      padding-left: 25px;
    }
  }
`;
