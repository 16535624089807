import * as React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'office-ui-fabric-react';

interface INoLicenseProps extends RouteComponentProps<{}> {
  environment_conf: {}
};

const NoLicense: React.StatelessComponent<INoLicenseProps> = (props) => (
    <Container>
        <p><b>You do not currently have a license for Spectacles.</b></p>

        <p>To obtain a license to use Spectales you must sign up for a subscription.</p>

        <p><PrimaryButton  onClick={function () { window.open(props.environment_conf.base_app_domain) }}>Get It Now</PrimaryButton></p>

        <p>If you believe your company already has a subscription, please reach out to your subscription administrator for assigning a seat to you.</p>
    </Container>
);

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - 200px);

  svg {
    color: ${props => props.theme.pallet.danger};
  }

  p {
    margin-top: 5px;
    max-width: 250px;
    text-align: center;
  }
`;

export default NoLicense;