import {
    ENABLE_REGEX_EXPRESSION,
    DISABLE_REGEX_EXPRESSION,
  } from '../constants/actionTypes';
  
  export type enableRegexExpressionType = (payload: number) => void;
  export const enableRegexExpression = (payload) => ({
    type: ENABLE_REGEX_EXPRESSION,
    payload
  });
  
  export type disableRegexExpressionType = (payload: number) => void;
  export const disableRegexExpression = (payload) => ({
    type: DISABLE_REGEX_EXPRESSION,
    payload
  });
  