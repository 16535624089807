import React, { useEffect, useState } from "react";

import { List } from "./styled";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { DefaultButton } from "@fluentui/react/lib/Button";

import ClauseNavigatorList from "../ClauseNavigatorList";

import ClauseListItem_Term from "./ClauseListItem_Term";
import ClauseListItem_Structure from "./ClauseListItem_Structure";

import ClauseDefinition from "../Clause/ClauseDefinition";

/**
 * Primary UI component for user interaction
 */
const ClauseListItem = (props) => {
  const [selectedChildClause, setSelectedChildClause] = useState();

  const handleClauseLinkClick = (clause) => {
    setSelectedChildClause(
      props.analysis.clauses.filter(
        (r) => r.word.toUpperCase() === clause.toUpperCase()
      )[0]
    );
  };

  return (
    <div
      style={{
        display: props.show ? "block" : "none",
        whiteSpace: "normal",
        float: "left",
        width: "100%",
        height: "auto",
        wordWrap: "break-word",
      }}
    >
      <ClauseDefinition
        handleClauseLinkClick={handleClauseLinkClick}
        clause={props.clause}
        clauses={props.analysis ? props.analysis.clauses : []}
        allowNavigateToClause={true}
      ></ClauseDefinition>

      {selectedChildClause && (
        <>
          <h3>{selectedChildClause.title}</h3>

          <ClauseListItem
            analysis={props.analysis}
            clause={selectedChildClause}
            show={props.show}
          ></ClauseListItem>
        </>
      )}
    </div>
  );
};

ClauseListItem.propTypes = {
  clause: PropTypes.object,
  analysis: PropTypes.object,
  show: PropTypes.bool,
};

ClauseListItem.defaultProps = {};

export default ClauseListItem;
