import * as React from "react";

import PropTypes from "prop-types";

const ClauseLink = (props) => {
  return (
    <span
      id={props.word}
      className={
        props.isCurrent
          ? "definition-highlight-current"
          : "definition-highlight"
      }
      onClick={() => props.handleClauseLinkClick(props.word)}
    >
      {props.text}
    </span>
  );
};

ClauseLink.propTypes = {
  isCurrent: PropTypes.bool,
  word: PropTypes.string,
  text: PropTypes.string,
  handleClauseLinkClick: PropTypes.func,
};

ClauseLink.defaultProps = {};

export default ClauseLink;
