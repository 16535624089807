import * as React from 'react';
import styled from 'styled-components';
import { Link, RouteComponentProps } from 'react-router-dom';
import { AlertTriangle, ChevronLeft } from 'react-feather';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - 80px);

  .alert {
    color: ${props => props.theme.pallet.warning};
  }

  p {
    margin-top: 5px;
    max-width: 200px;
    text-align: center;

    span {
      font-weight: 300;
    }
  }

  a {
    display: flex;
    align-items: center;
    color: ${props => props.theme.pallet.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    svg {
      margin-right: 2px;
    }
  }
`;

interface INotFoundScreenProps extends RouteComponentProps<any> {};

const TermNotFound: React.StatelessComponent<INotFoundScreenProps> = ({ match }) => {
  return (
    <Container>
      <AlertTriangle className="alert" />
      <p>No definition found for <span>"{match.params.term.trim()}"</span></p>
      <Link to="/">
        <ChevronLeft size={24} /> Return Home
      </Link>
    </Container>
  );
}

export default TermNotFound;
