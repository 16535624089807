import * as React from "react";

import { Section, Container } from "./styled";
import { withRouter } from "react-router";

import {
  Search,
  RefreshCw,
  ChevronLeft,
  ChevronRight,
  Home,
  Crosshair,
  Menu,
} from "react-feather";

import Patent from "../Patent";

import extractors from "../../extractors";

import PropTypes from "prop-types";

import { search } from "../../../utilities/clauseHelpers";

const BottomBar = (props) => {
  const searchText = () => {
    extractors.word.selection(function (selectedText) {
      if (!selectedText) return;

      const clause = search(props.clauses, selectedText);

      if (clause) {
        props.history.push(`/term/${clause.id}`);
      } else {
        props.history.push(`/term/not-found/${selectedText}`);
      }
    });
  };

  const isHomePage = props.location.pathname === "/";

  return (
    <Section>
      {!isHomePage ? (
        <Container>
          <button
            type="button"
            title="Home"
            onClick={() => props.history.push(`/`)}
            style={{ cursor: "pointer" }}
          >
            <Home />
          </button>
          <button
            type="button"
            title="Search for Term"
            onClick={() => searchText()}
            style={{ cursor: "pointer" }}
          >
            <Search />
          </button>
          <button
            type="button"
            title="Refresh Clauses"
            onClick={() => props.refreshClauses()}
            style={{ cursor: "pointer" }}
          >
            <RefreshCw />
          </button>
          <button
            type="button"
            title="Back"
            onClick={() => props.history.goBack()}
            style={{ cursor: "pointer" }}
          >
            <ChevronLeft />
          </button>
          <button
            title="Forward"
            type="button"
            onClick={() => props.history.goForward()}
            style={{ cursor: "pointer" }}
          >
            <ChevronRight />
          </button>
        </Container>
      ) : null}
      <div style={{ textAlign: "center" }}>
        <Patent></Patent>
      </div>
    </Section>
  );
};

BottomBar.propTypes = {
  goHome: PropTypes.func,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
  refreshClauses: PropTypes.func,
  search: PropTypes.func,
  clauses: PropTypes.array,
  mode: PropTypes.string,
};

BottomBar.defaultProps = {};

export default withRouter(BottomBar);
