import React from "react";

import { connect } from "react-redux";

import PropTypes from "prop-types";

import Clause from "../../components/Clause";

import { LICENSE_TYPES } from "../../interfaces/IUserInterface";

import {
  addSecondaryScrollTerm,
  removeSecondaryScrollTerm,
} from "../../actions/application";

const TermScreen = (props) => {
  const { id } = props.match.params;

  const navigateToScreen = (word) => {
    var clauseToNavTo = props.clauses.filter((r) => r.word === word)[0];
    props.history.push(`/term/${clauseToNavTo.id}`);
  };

  console.log("CLAUSES >>>>>>>> " + JSON.stringify(props.clauses));

  return (
    <Clause
      navigateToClause={navigateToScreen}
      clause={props.clauses.filter((r) => r.id === id)[0]}
      clauses={props.clauses}
      allowNavigateToClause={props.allowNavigateToClause}
      secondaryScrollTerms={props.secondaryScrollTerms}
      addSecondaryScrollTerm={props.addSecondaryScrollTerm}
      removeSecondaryScrollTerm={props.removeSecondaryScrollTerm}
    ></Clause>
  );
};

TermScreen.propTypes = {
  clauses: PropTypes.array,
};

TermScreen.defaultProps = {};

const mapStateToProps = (state) => ({
  clauses: state.terms.analysis.clauses,
  allowNavigateToClause: state.user.licenseType === LICENSE_TYPES.PREMIUM,
  secondaryScrollTerms: state.application.secondaryScrollTerms,
});

const mapDispatchToProps = (dispatch) => ({
  addSecondaryScrollTerm: (scrollTerm: string) =>
    dispatch(addSecondaryScrollTerm(scrollTerm)),
  removeSecondaryScrollTerm: (scrollTerm: string) =>
    dispatch(removeSecondaryScrollTerm(scrollTerm)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermScreen);
