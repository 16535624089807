import * as React from "react";
import styled, { ThemeProvider } from "styled-components";
import PropTypes from "prop-types";
var Modal = require("react-modal");
import { ModalHeader, ModalBody, ModalFooter } from "./styled";

const UpgradeModal = (props) => {
  const customStyles = {
    content: {
      bottom: "auto",
      padding: 0,
    },
  };

  const visitSpectacles = () => {
    window.open("https://app.spectaclesreader.com/Subscription");
  };

  return (
    <Modal isOpen={props.show} style={customStyles}>
      <ModalHeader>
        <h2>
          Upgrade to <span>Spectacles Premium</span> to unlock click-through.
        </h2>
      </ModalHeader>
      <ModalBody>
        <p>
          Click-through allows you to click on any Term found in any part of
          Spectacles to view it's defintion.
        </p>
        <br></br>
        <b>
          To upgrade your subscription please visit your account management page
          on <a href="app.spectaclesreader.com">our site.</a>
        </b>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="cancel" onClick={props.closeModal}>
          no thank you
        </button>
        <button type="button" className="upgrade" onClick={visitSpectacles}>
          Upgrade
        </button>
      </ModalFooter>
    </Modal>
  );
};

UpgradeModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
};

UpgradeModal.defaultProps = {};

export default UpgradeModal;
