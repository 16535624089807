const termsFinder = function (opts) {
  const ignoreWords = [
    "and",
    "or",
    "include",
    "includes",
    "including",
    "without limitation",
    "will",
    "shall",
    "herein",
    "hereof",
    "hereby",
    "hereto",
    "hereunder",
    "from",
    "from and including",
    "to",
    "until",
    "through",
    "as is",
  ];

  const ignoreTerm = function (term) {
    if (ignoreWords.indexOf(term.toLowerCase()) > -1) return true;

    if (term.length < 2) return true;

    return false;
  };

  const areEquivalentTerms = function (term, candidateTerm) {
    if (candidateTerm.toUpperCase() == term.toUpperCase()) return true;

    if (term == candidateTerm) return true;

    var termEquivs = getEquivalentTerms(term);
    if (termEquivs.indexOf(candidateTerm) > -1) return true;

    var candidateTermEquivs = getEquivalentTerms(candidateTerm);
    if (candidateTermEquivs.indexOf(term) > -1) return true;

    return false;
  };

  const getEquivalentTerms = function (term) {
    var t = [];

    t.push(term);
    t.push(term + "s");
    t.push(term + "es");
    t.push(term + "’s");
    t.push(term + "’");
    t.push(term + "'s");
    t.push(term + "'");

    if (term.substring(term.length - 2, term.length).toUpperCase() == "ES")
      t.push(term.substring(0, term.length - 2));
    else if (term.substring(term.length - 1, term.length).toUpperCase() == "S")
      t.push(term.substring(0, term.length - 1));
    if (term.substring(term.length - 1, term.length).toUpperCase() == "Y")
      t.push(term.substring(0, term.length - 1) + "ies");
    if (term.substring(term.length - 3, term.length).toUpperCase() == "IES")
      t.push(term.substring(0, term.length - 3) + "y");

    return t;
  };

  const mergeTerms = function (termToKeep, termToRemove) {
    if (termToRemove.definition) {
      if (!termToKeep.definition) termToKeep.definition = [];

      for (var i = 0; i < termToRemove.definition.length; i++) {
        if (
          !termToKeep.definition.find(
            (r) => r.html == termToRemove.definition[i].html
          )
        ) {
          termToKeep.definition.push(termToRemove.definition[i]);
        }
      }
    }

    if (termToKeep.definedDefinition || termToRemove.definedDefinition)
      if (termToRemove.definedDefinition) {
        if (!termToKeep.definedDefinition) termToKeep.definedDefinition = [];

        for (var i = 0; i < termToRemove.definedDefinition.length; i++) {
          if (
            !termToKeep.definedDefinition.find(
              (r) => r.html == termToRemove.definedDefinition[i].html
            )
          ) {
            termToKeep.definedDefinition.push(
              termToRemove.definedDefinition[i]
            );
          }
        }
      }

    //termToKeep.definedDefinition = termToRemove.definedDefinition;
    termToKeep.definedTableDetected =
      termToRemove.definedTableDetected || termToKeep.definedTableDetected;

    termToKeep.inlineTableDetected =
      termToRemove.inlineTableDetected || termToKeep.inlineTableDetected;

    if (
      termToKeep.word != termToRemove.word &&
      !termToKeep.alternativeTerms.find((r) => r.word == termToRemove.word)
    ) {
      termToKeep.alternativeTerms.push({
        word: termToRemove.word,
        match: termToRemove.match,
        id: termToRemove.id,
      });
    }

    for (var j = 0; j < termToKeep.definedDefinition?.length; j++) {
      var d = termToKeep.definition.find(
        (r) => r.html == termToKeep.definedDefinition[j].html
      );

      if (d) {
        termToKeep.definition.splice(termToKeep.definition.indexOf(d), 1);
      }
    }

    return termToKeep;
  };

  if (opts) {
  }

  const guidGenerator = function () {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  };

  const initAlternativeTerms = function (word) {
    var equivalentTerms = getEquivalentTerms(word);

    var t = [];

    for (var i = 0; i < equivalentTerms.length; i++) {
      t.push({
        word: equivalentTerms[i],
        match: equivalentTerms[i],
        id: guidGenerator(),
      });
    }

    return t;
  };

  const getInlineDefinitions = function (paragraphs) {
    //const termFoundInSentenceRegex = /\((.)*“[\w\s-&()]*”(.)*\)/g;
    //const termRegex = /“[\w\s-&()]*”/g;

    const termFoundInSentenceRegex = /\((.)*(“|")[\w\s-&()]*(”|")(.)*\)/g;
    const termRegex = /(“|")[\w\s-&()]*(”|")/g;

    let definitionList = [];

    paragraphs.forEach((paragraph, paragraphIndex) => {
      if (paragraph.text === "") {
        return;
      }

      if (!paragraph.isParagraph && !paragraph.isTable) {
        return;
      }

      const cleanParagraph = paragraph.text.trim();
      const termFoundInParagraph = cleanParagraph.match(
        termFoundInSentenceRegex
      );

      var whyStop = "";
      let inlineParagraphs = [];

      if (termFoundInParagraph !== null) {
        inlineParagraphs.push(paragraph);

        let tableDetected = false;

        if (cleanParagraph.slice(-1) !== ".") {
          let paragraphEndFound = false;
          let count = 0;
          let nextParagraphIndex = paragraphIndex;

          while (!paragraphEndFound && count < 50) {
            count += 1;
            nextParagraphIndex += 1;

            if (!paragraphs[nextParagraphIndex]) {
              continue;
            }

            if (paragraphs[nextParagraphIndex].text === "") {
              continue;
            }

            if (paragraphs[nextParagraphIndex].isTable > 0) {
              tableDetected = true;
              continue;
            }

            if (paragraphs[nextParagraphIndex].tableNestingLevel > 0) {
              tableDetected = true;
              continue;
            }

            inlineParagraphs.push(paragraphs[nextParagraphIndex]);

            if (paragraphs[nextParagraphIndex].text.slice(-1) === ".") {
              paragraphEndFound = true;
              continue;
            }
          }
        }

        const terms = cleanParagraph.match(termRegex);

        terms.forEach((term) => {
          let cleanWord = term.replace("“", "");
          cleanWord = cleanWord.replace("”", "");
          cleanWord = cleanWord.replaceAll('"', "");

          if (ignoreTerm(cleanWord)) {
            return;
          }

          definitionList.push({
            id: guidGenerator(),
            match: term,
            word: cleanWord,
            title: cleanWord,
            definition: [...inlineParagraphs],
            inlineTableDetected: tableDetected,
            definedTableDetected: false,
            alternativeTerms: initAlternativeTerms(cleanWord),
            type: "term",
          });
        });
      }
    });

    // Remove duplicate words found.
    definitionList = definitionList.filter(
      (definition, index, self) =>
        index ===
        self.findIndex(
          (def) => def.word.toUpperCase() === definition.word.toUpperCase()
        )
    );

    //merge inline definitions for equivalent terms
    let i = definitionList.length;
    while (i--) {
      var definition = definitionList.find((d) => {
        return (
          getEquivalentTerms(d.word)
            .filter((r) => r.toUpperCase() != d.word.toUpperCase())
            .map(function (w) {
              return w.toUpperCase();
            })
            .indexOf(definitionList[i].word.toUpperCase()) > -1 ||
          getEquivalentTerms(definitionList[i].word)
            .filter((r) => r.toUpperCase() != d.word.toUpperCase())
            .map(function (w) {
              return w.toUpperCase();
            })
            .indexOf(d.word.toUpperCase()) > -1
        );
      });

      if (definition) {
        definition = mergeTerms(definition, definitionList[i]);
        definitionList.splice(i, 1);
      }
    }

    // console.log('definitionList: ', definitionList);

    return definitionList;
  };

  const getDefinedDefinitions = function (paragraphs, inlineDefinitions) {
    const definedDefinitionList = [];
    //const definedDefinitionRegEx = /^([\d.\t\s]*)?(“[^“”]*”)/gm; // New, better.
    //const wordRegEx = /“[^“”]*”/g; // New, better.

    const definedDefinitionRegEx = /^([\d.\t\s]*)?((“|")[^“”"]*(”|"))/gm; // New, better.
    const wordRegEx = /(“|")[^“”"]*(”|")/g; // New, better.

    // for (const paragraph of paragraphs.items) {
    paragraphs.forEach((paragraph, index) => {
      if (paragraph.text === "") {
        return;
      }

      if (!paragraph.isParagraph && !paragraph.isTable) {
        return;
      }

      let definedDefinitionMatch;

      if (paragraph.isParagraph && !paragraph.belongsToTable) {
        definedDefinitionMatch = paragraph.text.match(definedDefinitionRegEx);
      }

      //console.log('definedDefinitionMatch: ', definedDefinitionMatch);

      if (definedDefinitionMatch) {
        const word = definedDefinitionMatch[0].match(wordRegEx);
        // console.log('word: ', word);

        if (word !== null) {
          let tableDetected = false;
          let currentWord = word[0].replace("“", "");
          currentWord = currentWord.replace("”", "");
          currentWord = currentWord.replaceAll('"', "");

          // Get definition. This checks to see if the current paragraph ends with a "."
          // If it doesn't, continue to get the next paragraph until one is found that ends
          // with a ".". Count is added as safeguard.
          const definitionParagraphs = [];
          const cleanParagraph = paragraph.text.trim();
          definitionParagraphs.push(paragraph);

          if (
            cleanParagraph.slice(-1) !== "." ||
            !paragraphs[index + 1].isParagraph
          ) {
            let paragraphEndFound = false;
            let count = 0;
            let nextParagraphIndex = index;
            let endOnNext = false;

            while (!paragraphEndFound && count < 50) {
              count += 1;
              nextParagraphIndex += 1;

              if (endOnNext) {
                paragraphEndFound = true;
                continue;
              }

              if (!paragraphs[nextParagraphIndex]) {
                endOnNext = true;
                continue;
              }

              if (paragraphs[nextParagraphIndex].text === "") {
                continue;
              }

              if (paragraphs[nextParagraphIndex].isTable) {
                tableDetected = true;
              }

              if (paragraphs[nextParagraphIndex].tableNestingLevel > 0) {
                tableDetected = true;
              }

              if (
                paragraphs[nextParagraphIndex].belongsToTable &&
                !paragraphs[nextParagraphIndex].isTable
              ) {
                tableDetected = true;
                continue;
              }

              if (
                !paragraphs[nextParagraphIndex].isParagraph &&
                !paragraphs[nextParagraphIndex].isTable
              ) {
                endOnNext = true;
              }

              if (
                paragraphs[nextParagraphIndex].text.match(
                  definedDefinitionRegEx
                )
              ) {
                endOnNext = true;
                continue;
              }

              definitionParagraphs.push(paragraphs[nextParagraphIndex]);

              if (
                paragraphs[nextParagraphIndex].text.slice(-1) === "." &&
                paragraphs[nextParagraphIndex].isParagraph
              ) {
                paragraphEndFound = true;
              }
            }
          }

          definedDefinitionList.push({
            id: guidGenerator(),
            match: definedDefinitionMatch,
            word: currentWord,
            title: currentWord,
            definition: null,
            definedDefinition: definitionParagraphs,
            definedTableDetected: tableDetected,
            alternativeTerms: initAlternativeTerms(currentWord),
            type: "term",
          });
        }
      }
    });

    // Compare inline terms against newly found defined terms and if a duplicate word is found
    // then add the definedTerm's defintion to the inlineTerm's "definedDefinition".
    let i = definedDefinitionList.length;
    while (i--) {
      const definedDefinition = definedDefinitionList[i];
      var inLineDefinition = inlineDefinitions.find((inlineDefinition) => {
        var termFoundByMatch =
          definedDefinition.word.toUpperCase() ===
          inlineDefinition.word.toUpperCase();
        var termFoundByAlternativeTerms =
          inlineDefinition.alternativeTerms
            .map(function (w) {
              return w.word.toUpperCase();
            })
            .indexOf(definedDefinition.word.toUpperCase()) > -1;
        var termFoundByEquivalentTerms =
          getEquivalentTerms(inlineDefinition.word)
            .map(function (w) {
              return w.toUpperCase();
            })
            .indexOf(definedDefinition.word.toUpperCase()) > -1;
        var termFoundByEquivalentDefinedTerms =
          getEquivalentTerms(definedDefinition.word)
            .map(function (w) {
              return w.toUpperCase();
            })
            .indexOf(inlineDefinition.word.toUpperCase()) > -1;

        var found =
          termFoundByMatch ||
          termFoundByAlternativeTerms ||
          termFoundByEquivalentTerms ||
          termFoundByEquivalentDefinedTerms;

        return found;
      });

      if (inLineDefinition) {
        inLineDefinition = mergeTerms(
          inLineDefinition,
          definedDefinitionList[i]
        );
        definedDefinitionList.splice(i, 1);
      }
    }

    return definedDefinitionList;
  };

  return {
    getTerms: function (dom) {
      var ps = dom.querySelectorAll("p, table, h1, h2, h3, h4, h5, h6");

      var paragraphs = [];
      ps.forEach(function (item, idx) {
        paragraphs.push({
          html: (item.outerHTML ?? "")
            .replaceAll("‚Äú", "“")
            .replaceAll("‚Äù", "”")
            .replaceAll("&nbsp;", " ")
            .replaceAll(/[\u202F\u00A0]/g, " ")
            .replaceAll(/\s\s+/g, " "),
          text: (item.textContent.trim() ?? "")
            .replaceAll("‚Äú", "“")
            .replaceAll("‚Äù", "”")
            .replaceAll("&nbsp;", " ")
            .replaceAll(/[\u202F\u00A0]/g, " ")
            .replaceAll(/\s\s+/g, " "),
          isParagraph:
            item.localName == "p" ||
            item.localName == "h1" ||
            item.localName == "h2" ||
            item.localName == "h3" ||
            item.localName == "h4" ||
            item.localName == "h5" ||
            item.localName == "h6",
          isTable: item.localName == "table",
          belongsToTable: item.closest("table"),
        });
      });

      const inlineTerms = getInlineDefinitions(paragraphs);
      const definedTerms = getDefinedDefinitions(paragraphs, inlineTerms);

      const definitions = [...inlineTerms, ...definedTerms];

      definitions.sort((a, b) => {
        const nameA = a.word.toLowerCase();
        const nameB = b.word.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      //console.log("finial definitions: ", definitions);

      return definitions;
    },
    areEquivalentTerms,
  };
};

export default termsFinder;
