import styled from "styled-components";

export const RedText = styled.span`
  color: red;
  text-decoration: line-through;
`;

export const GreenText = styled.span`
  color: ${(props) => props.theme.pallet.primary};
`;

export const Text = styled.span``;
