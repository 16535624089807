import styled from "styled-components";

export const PatentLink = styled.a`
    color: ${(props) => props.theme.pallet.primary};
    cursor: pointer;
    font-size: 12px;
    width: 100%;
    text-align: center;
`;

export const ModalHeader = styled.div`
  background-color: ${(props) => props.theme.pallet.primary};
  color: ${(props) => props.theme.pallet.white};
  text-align: center;
`;

export const ModalBody = styled.div`
  p {
    margin: 0;
    padding-top: 10px;
  }

  padding-bottom: 10px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  .cancel {
    background-color: transparent;
    margin-right: 20px;
    color: #686868;
    font-weight: 100;

    &:hover {
      background-color: ${(props) => props.theme.pallet.primaryLighter};
    }
  }
`;
