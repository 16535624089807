import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import PropTypes from "prop-types";

import TermsLoading from "../../components/TermsLoading";

import extractors from "../../extractors";
import server from "../../../utilities/server/index";
import analyzeDocument from "../../anaylsis/regex/analyzeDocument";
import convertRegexResponseData from "../../anaylsis/regex/convertResponseData";
import convertAiResponseData from "../../anaylsis/ai/convertResponseData";

import { setAnalysis, setAnalysisType } from "../../actions/terms";

import {
  LICENSE_TYPES,
  ANALYSIS_ENGINE,
} from "../../interfaces/IUserInterface.ts";

const Analyzing = (props) => {
  const [loadingText, setLoadingText] = useState();

  useEffect(() => {
    const runAnalysis = async (html) => {
      //request analysis
      let analysis;
      let converted;

      if (props.user.analysisEngine == ANALYSIS_ENGINE.AI) {
        const resp = await server.getAnalysis(props.user.accessToken, html);
        converted = convertAiResponseData(resp.data.data);
      } else {
        //promisefy
        analysis = analyzeDocument(html);
        converted = convertRegexResponseData(analysis);
      }

      props.setAnalysis(converted);
    };

    setLoadingText("Extracting text from document.");
    extractors.word.document(function (html) {
      setLoadingText("License Retrieved.  Analyzing Document.");
      runAnalysis(html);
    });
  }, []);

  return <TermsLoading text={loadingText} />;
};

Analyzing.propTypes = {
  accessToken: PropTypes.string,
};

Analyzing.defaultProps = {};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setAnalysis: (analysis: any) => dispatch(setAnalysis(analysis)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Analyzing);
