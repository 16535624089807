import React, { useEffect, useState } from "react";
import axios from "axios";

import { connect } from "react-redux";
import { Pivot, PivotItem, PivotLinkSize } from "@fluentui/react/lib/Pivot";

import Clauses from "../../screens/Clauses/";
import { environment_conf } from "../../../utilities/environment";

import convertResponseData from "../../anaylsis/ai/convertResponseData";

import PropTypes from "prop-types";

/**
 * Primary UI component for user interaction
 */
const ClauseNavigatorContainer = (props) => {
  const [loading, setLoading] = useState(true);
  const [analysis, setAnalysis] = useState(undefined);
  const [history, setHistory] = useState(undefined);

  useEffect(() => {
    const bodyHtml = props.bodyHtml;

    const getAnalysis = async () => {
      const resp = await axios.post(
        `${environment_conf.base_ai_domain}/analyze`,
        {
          bodyHtml,
        }
      );

      setAnalysis(convertResponseData(resp.data.data));
    };

    getAnalysis();
  }, []);

  useEffect(() => {
    console.log(analysis);
    setLoading(!analysis);
  }, [analysis]);

  const changeSection = (section) => {
    var h = {
      location: {
        state: {
          section: section,
          clause: undefined,
        },
      },
    };

    setHistory(h);
  };

  return (
    <>
      {loading && <div> One second while we load your information </div>}
      {!loading && (
        <div>
          <Clauses
            analysis={analysis}
            changeSection={changeSection}
            history={history}
          ></Clauses>{" "}
        </div>
      )}
    </>
  );
};

export default ClauseNavigatorContainer;
