import {
  SET_SCROLL_TERM,
  ADD_SECONDARY_SCROLL_TERM,
  REMOVE_SECONDARY_SCROLL_TERM,
  CLEAR_SECONDARY_SCROLL_TERM,
  SET_IS_GRAPH_DIALOG,
} from "../constants/actionTypes";
import { ITerm } from "../interfaces/ITermsInterface";

export type setScrollTerm = (payload: ITerm) => void;
export const setScrollTerm = (payload) => ({
  type: SET_SCROLL_TERM,
  payload,
});

export type addSecondaryScrollTerm = (payload: string) => void;
export const addSecondaryScrollTerm = (payload) => ({
  type: ADD_SECONDARY_SCROLL_TERM,
  payload,
});

export type removeSecondaryScrollTerm = (payload: string) => void;
export const removeSecondaryScrollTerm = (payload) => ({
  type: REMOVE_SECONDARY_SCROLL_TERM,
  payload,
});

export type clearSecondaryScrollTerm = (payload: string) => void;
export const clearSecondaryScrollTerm = (payload) => ({
  type: CLEAR_SECONDARY_SCROLL_TERM,
  payload,
});

export type setIsGraphDialog = (payload: bool) => void;
export const setIsGraphDialog = (payload) => ({
  type: SET_IS_GRAPH_DIALOG,
  payload,
});
