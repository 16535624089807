import {
  SET_TERMS_START,
  SET_TERMS_SUCCESS,
  SET_TERMS_SEARCHING_DEFINED,
  SET_TERMS_MERGING,
  SET_CURRENT_TERM_OFFSET_TOP,
  SET_TOC_SUCCESS,
  SET_ANALYSIS,
  ADD_RECOMMENDATIONS,
  MARK_RECOMMENDATION_AS_INSERTED,
} from "../constants/actionTypes";
import { ITerms } from "../interfaces/ITermsInterface";

export const initialState: ITerms = {
  loadingStatus: "Scanning for Inline Terms",
  terms: null,
  currentTermOffsetTop: 0,
  tableOfContents: null,
  analysis: null,
  recommendations: [],
};

export default function (state: ITerms = initialState, action) {
  switch (action.type) {
    case SET_TERMS_START:
      return {
        ...state,
        terms: null,
      };
    case SET_TERMS_SEARCHING_DEFINED:
      return {
        ...state,
        loadingStatus: "Getting Defined Terms",
      };
    case SET_TERMS_MERGING:
      return {
        ...state,
        loadingStatus: "Finalizing Terms",
      };
    case SET_TERMS_SUCCESS:
      return {
        ...state,
        currentTermOffsetTop: 0,
        loadingStatus: "Success",
        terms: action.payload,
      };
    case SET_TOC_SUCCESS:
      return {
        ...state,
        tableOfContents: action.payload,
      };
    case SET_CURRENT_TERM_OFFSET_TOP:
      return {
        ...state,
        currentTermOffsetTop: action.payload,
      };
    case SET_ANALYSIS:
      return {
        ...state,
        loadingStatus: "Success",
        analysis: action.payload,
      };
    case ADD_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: state.recommendations.concat(
          action.payload.filter((r) => state.recommendations.map((s) => s.word).indexOf(r.word) == -1)
        ),
      };
    case MARK_RECOMMENDATION_AS_INSERTED:
      return {
        ...state,
        recommendations: state.recommendations.map((r) => {
          if (r.word === action.payload) {
            r.inserted = true;
            return r;
          } else return r;
        }),
      };
    default:
      return state;
  }
}
