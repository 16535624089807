import styled from "styled-components";

export const Section = styled.section`
  padding: 20px 15px;
  height: 100%;
  width: 100%;
  overflow-y: scroll;

  .page-title {
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const Container = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  box-shadow: ${(props) => props.theme.ui.boxShadow};
  min-height: ${(props) => props.minHeight};
`;
