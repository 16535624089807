import styled from 'styled-components';
import { Container } from '../ui/Grid'

export const ClauseContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  box-shadow: ${props => props.theme.ui.boxShadow};
`;

export const TitleContainer = styled.div`
  display: flex;
  
  h3 {
    margin-right: 10px;
  }

  .warning {
    color: orange;
  }
`;

export const Definition = styled.div`
  padding: 20px;
  line-height: 1.4;

  p {
    margin-top: 0;
  }

  .definition-highlight-current {
    padding: 2px 8px;
    background-color: ${props => props.theme.pallet.yellowLight};
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    line-height: 1.5;

    &:hover {
      background-color: ${props => props.theme.pallet.yellow};
      /* color: ${props => props.theme.pallet.white}; */
    }
  }

  .definition-highlight {
    padding: 2px 8px;
    background-color: ${props => props.theme.pallet.primaryLight};
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    line-height: 1.5;

    &:hover {
      background-color: ${props => props.theme.pallet.primary};
      color: ${props => props.theme.pallet.white};
    }
  }
`;