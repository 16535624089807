import {
  ENABLE_REGEX_EXPRESSION,
  DISABLE_REGEX_EXPRESSION,
} from "../constants/actionTypes";

export const initialState = [
  {
    id: 1,
    //text: '/(“|")[ws-&()]*(”|")/g',
    //getTermRegex: /(“|")[\w\s-&()]*(”|")/g,
    //findTermInParagraphRegex: /\((.)*(“|")[\w\s-&()]*(”|")(.)*\)/g,
    text: '/(“|")[^“”"]*(”|")/g',
    getTermRegex: /(“|")[^“”"]*(”|")/g,
    findTermInParagraphRegex: /^([\d.\t\s]*)?((“|")[^“”"]*(”|"))/gm,
    enabled: true,
  },

  {
    id: 2,
    text: "/(exhibit (S*))(s?[a-z][^.]{,64}.)?/g,",
    getTermRegex: /(exhibit (\S*))(\s?[a-z][^\.]{,64}\.)?/g,
    findTermInParagraphRegex: /(exhibit (\S*))(\s?[a-z][^\.]{,64}\.)?/g,
    enabled: true,
  },

  {
    id: 3,
    text: "/(schedules? (S*))(s?[a-z][^.]{,64}.)?/g",
    getTermRegex: /(schedules? (\S*))(\s?[a-z][^\.]{,64}\.)?/g,
    findTermInParagraphRegex: /(schedules? (\S*))(\s?[a-z][^\.]{,64}\.)?/g,
    enabled: true,
  },

  {
    id: 4,
    text: "/(article (S*))(s?[a-z][^.]{,64}.)?/g",
    getTermRegex: /(article (\S*))(\s?[a-z][^\.]{,64}\.)?/g,
    findTermInParagraphRegex: /(article (\S*))(\s?[a-z][^\.]{,64}\.)?/g,
    enabled: true,
  },

  {
    id: 5,
    text: "/(section (S*))(s?[a-z][^.]{,64}.)?/g",
    getTermRegex: /(section (\S*))(\s?[a-z][^\.]{,64}\.)?/g,
    //findTermInParagraphRegex: /(section (\S*))(\s?[a-z][^\.]{,64}\.)?/g,
    findTermInParagraphRegex: /^([\d.\t\s]*)?((section (\S*))(\s?[a-z][^\.]{,64}\.)?)/gm,
    enabled: true,
  },
  /*
  {
    id: 6,
    text: '/(\d{1,2}\.)\s?([^\.]{,64}\.)?/g',
    getTermRegex:/(\d{1,2}\.)\s?([^\.]{,64}\.)?/g,
    findTermInParagraphRegex: /(\d{1,2}\.)\s?([^\.]{,64}\.)?/g,
    enabled: true,
  },

  {
    id: 7,
    text: '/(\(\d{1,4}\))(\s?[a-z][^\.]{,64}\.)?/g',
    getTermRegex: /(\(\d{1,4}\))(\s?[a-z][^\.]{,64}\.)?/g,
    findTermInParagraphRegex: /(\(\d{1,4}\))(\s?[a-z][^\.]{,64}\.)?/g,
    enabled: true,
  },

  {
    id: 8,
    text: '/(\d{1,2}\.([\d]{1,2}\.?)*)(\s?[a-z][^\.]{,64}\.)?/g',
    getTermRegex:/(\d{1,2}\.([\d]{1,2}\.?)*)(\s?[a-z][^\.]{,64}\.)?/g,
    findTermInParagraphRegex:/(\d{1,2}\.([\d]{1,2}\.?)*)(\s?[a-z][^\.]{,64}\.)?/g,
    enabled: true,
  },

  {
    id: 9,
    text: '/(\([ivx]{1,4}\))(\s?[a-z][^\.]{,64}\.)?/g',
    getTermRegex: /(\([ivx]{1,4}\))(\s?[a-z][^\.]{,64}\.)?/g,
    findTermInParagraphRegex: /(\([ivx]{1,4}\))(\s?[a-z][^\.]{,64}\.)?/g,
    enabled: true,
  },

  {
    id: 10,
    text: '/([ivx]{1,4}\.)(\s?[a-z][^\.]{,64}\.)?/g',
    getTermRegex: /([ivx]{1,4}\.)(\s?[a-z][^\.]{,64}\.)?/g,
    findTermInParagraphRegex: /([ivx]{1,4}\.)(\s?[a-z][^\.]{,64}\.)?/g,
    enabled: true,
  },

  {
    id: 11,
    text: '/(\([a-h]{1,4}\))(\s?[a-z][^\.]{,64}\.)?/g',
    getTermRegex: /(\([a-h]{1,4}\))(\s?[a-z][^\.]{,64}\.)?/g,
    findTermInParagraphRegex:/(\([a-h]{1,4}\))(\s?[a-z][^\.]{,64}\.)?/g,
    enabled: true,
  },

  {
    id: 12,
    text: '/([\da-z]{1,2}\.([\da-z]{1,2}\.?)*)(\s?[a-z][^\.]{,64}\.)?/g',
    getTermRegex: /([\da-z]{1,2}\.([\da-z]{1,2}\.?)*)(\s?[a-z][^\.]{,64}\.)?/g, 
    findTermInParagraphRegex: /([\da-z]{1,2}\.([\da-z]{1,2}\.?)*)(\s?[a-z][^\.]{,64}\.)?/g, 
    enabled: true,
  },
  */
];

export default function (state = initialState, action) {
  switch (action.type) {
    case ENABLE_REGEX_EXPRESSION:
      return state.map((exp, i) =>
        exp.id === action.payload ? { ...exp, enabled: true } : exp
      );
    case DISABLE_REGEX_EXPRESSION:
      return state.map((exp, i) =>
        exp.id === action.payload ? { ...exp, enabled: false } : exp
      );
    default:
      return state;
  }
}
