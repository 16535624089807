import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0078D4;
  color: #fff;
  box-shadow: ${(props) => props.theme.ui.boxShadow};
  margin-bottom: 3px;

  .sky-line {
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: .10;
  }

  span {
    display: flex;
    align-items: center;

    h1 {
      font-size: 1.8rem;
      margin-left: 8px;
    }
  }
`;

export default () => (
  <Container>
  { /*
    <img
      src='/assets/new-york-skyline-clean.png'
      alt='New York Skyline Outline'
      className="sky-line"
    />  
    */ }  

    <span>
      <img
        width='200'
        height='85'
        src='/assets/logo-white.png'
        alt='Spectacles Logo'
      />
    </span>
  </Container>
);