export default {
  pallet: {
    primary: 'rgb(0, 120, 212)',
    primaryLight: 'rgba(0, 120, 212, .15)',
    primaryLighter: 'rgba(0, 120, 212, .05)',

    white: 'rgb(255, 255, 255)',
    black: 'rgb(0, 0, 0)',
    yellow: 'rgb(255, 255, 0)',
    yellowLight: 'rgb(255, 255, 127)',
    
    danger: 'red',
    warning: 'rgb(234, 128, 21)',
  },
  ui: {
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.50)',
    boxShadowVertical: '0 -1px 4px 0 rgba(0,0,0,0.1)',
  },
  breakPoints: {
    small: '400px',
  }
}