export default function compareClauses(a, b) {
  function compareSectionNumbers(a, b) {
    const splitA = a.split(".");
    const splitB = b.split(".");

    if (splitA.length == 1 || splitB.length == 1 || splitA[0] != splitB[0]) {
      return parseFloat(splitA[0]) > parseFloat(splitB[0]) ? 1 : -1;
    } else {
      return parseFloat(splitA[1]) > parseFloat(splitB[1]) ? 1 : -1;
    }
  }

  if (
    a.definedDefinition &&
    b.definedDefinition &&
    a.definedDefinition[0] &&
    b.definedDefinition[0] &&
    a.title === a.definedDefinition[0].text &&
    a.definedDefinition.length === 1 &&
    b.title != b.definedDefinition[0].text
  ) {
    return 1;
  }

  if (
    a.definedDefinition &&
    b.definedDefinition &&
    a.definedDefinition[0] &&
    b.definedDefinition[0] &&
    a.title != a.definedDefinition[0].text &&
    b.title === b.definedDefinition[0].text &&
    b.definedDefinition.length === 1
  ) {
    return -1;
  }

  if (a.word === b.word)
    return a.title.toUpperCase() > b.title.toUpperCase() ? 1 : -1;

  if (isNaN(a.word) || isNaN(b.word)) {
    const matchNumberRegex = /[0-9][0-9,\.]*/;
    const matchWordRegex = /[a-zA-Z]+/;

    const wordMatchA = a.word.match(matchWordRegex)
      ? a.word.match(matchWordRegex)[0]
      : null;

    const wordMatchB = b.word.match(matchWordRegex)
      ? b.word.match(matchWordRegex)[0]
      : null;

    const matchA = a.word.match(matchNumberRegex)
      ? a.word.match(matchNumberRegex)[0]
      : null;
    const matchB = b.word.match(matchNumberRegex)
      ? b.word.match(matchNumberRegex)[0]
      : null;

    if (!matchA || !matchB) {
      return a.word.toUpperCase() > b.word.toUpperCase() ? 1 : -1;
    } else if (
      wordMatchA &&
      wordMatchB &&
      wordMatchA.toUpperCase() != wordMatchB.toUpperCase()
    ) {
      return wordMatchA.toUpperCase() < wordMatchB.toUpperCase() ? 1 : -1;
    } else {
      return compareSectionNumbers(matchA, matchB);
    }
  }

  return compareSectionNumbers(a.word, b.word);
}
