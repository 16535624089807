import * as React from 'react';
import styled from 'styled-components';
import DefaultHeader from '../DefaultHeader';


export default () => (
    <Container>
        <p>There was an error getting your license details.  Support has been notified.</p>
    </Container>
);

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - 200px);

  svg {
    color: ${props => props.theme.pallet.danger};
  }

  p {
    margin-top: 5px;
    max-width: 200px;
    text-align: center;
  }
`;