const axios = require("axios").default;
import { LICENSE_TYPES } from "../../src/interfaces/IUserInterface";
import { environment_conf } from "../environment";

export const getDomainHint = async (emailAddress) => {
  var resp = await axios.get(
    conf.base_app_domain + `/domainHints/get?email=${emailAddress}`
  );
  return await resp.json();
};

export const getLicense = async (accessKey) => {
  var baseDomain = environment_conf.base_app_domain;

  const config = {
    headers: { Authorization: "Bearer " + accessKey },
  };

  var resp = await axios.get(baseDomain + "/seats/getSeat", config);
  return resp;
};

export const getClioDocs = async (accessKey, parentId) => {
  var baseDomain = environment_conf.base_app_domain;

  const config = {
    headers: { Authorization: "Bearer " + accessKey },
  };

  var resp = await axios.get(
    baseDomain + "/clio/App_Get?parentId=" + parentId,
    config
  );

  return await resp.json();
};

export const getClioDoc = async (accessKey, docId) => {
  var baseDomain = environment_conf.base_app_domain;

  const config = {
    headers: { Authorization: "Bearer " + accessKey },
  };

  var resp = await axios.get(
    baseDomain + "/clio/App_Document?documentId=" + docId,
    config
  );
  return await resp.json();
};

export const getAnalysis = async (accessKey, bodyHtml) => {
  return await axios.post(`${environment_conf.base_ai_domain}/analyze`, {
    bodyHtml,
  });
};

export const semanticSearch = async (
  query,
  suggestions_to_retrieve,
  children_levels
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await axios.post(
    `${environment_conf.base_ai_domain}/semantic_search`,
    {
      query,
      suggestions_to_retrieve,
      children_levels,
    },
    { headers: headers }
  );
};

export const generateDefinition = async (
  query,
  suggestions_to_retrieve,
  children_levels
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await axios.post(
    `${environment_conf.base_ai_domain}/generate_definition`,
    {
      query,
      suggestions_to_retrieve,
      children_levels,
    },
    { headers: headers }
  );
};

export const generateSection = async (
  query_section,
  written_sections,
  suggestions_to_retrieve,
  children_levels
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return await axios.post(
    `${environment_conf.base_ai_domain}/generate_section`,
    {
      query_section,
      written_sections,
      suggestions_to_retrieve,
      children_levels,
    },
    { headers: headers }
  );
};
