import {
  INCREASE_FONT_SIZE,
  DECREASE_FONT_SIZE,
  CHANAGE_FONT_FAMILY,
  RESET_TO_DEFAULT
} from '../constants/actionTypes';

export const initialState = {
  size: 0,
  family: 'calibri',
}

export default function (state = initialState, action) {
  switch(action.type) {
    case INCREASE_FONT_SIZE:
      return {
        ...state,
        size: state.size + action.payload,
      }
    case DECREASE_FONT_SIZE:
      return {
        ...state,
        size: state.size - action.payload,
      }
    case CHANAGE_FONT_FAMILY:
      return {
        ...state,
        family: action.payload,
      }
    case RESET_TO_DEFAULT:
     return {
       size: 0,
       family: 'calibri',
     }
    default: 
      return state
  }
}
