import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import "./style.css";
import { updateUserAiSetting } from "../../../utilities/server";
import { IUser, User } from "../../interfaces/IUserInterface";
import { setUser } from "../../actions/user";
import { toast } from "react-toastify";
import {
  togetherModels,
  openAiModels,
  providers,
  anthropicModels,
  mistralModels,
} from "../../../utilities/constants";
import { modelType } from "../../../utilities/types";
import { ITerm } from "../../interfaces/ITermsInterface";
import { customLog, ingestDoc } from "../../../utilities/utils";
import { getApiKeys, getUser } from "../../../utilities/server";

type modelNames = "anthropic" | "openai" | "togetherai" | "mistralai";

type Props = {
  terms: ITerm[];
  sections: ITerm[];
  user: User;
  setUser: (user: IUser) => any;
  userId: string;
  provider: modelNames;
  model: string;
  organId: string;
  aikey: string;
  setUpdatedApiKey: (apiKey: string) => any;
  setUpdatedModel: (model: string) => any;
  setSelectedModelId: (modelId: number) => any;
};

export const AiSettings = ({
  user,
  setUser,
  userId,
  provider,
  model,
  organId,
  aikey,
  setUpdatedApiKey,
  setUpdatedModel,
  setSelectedModelId
}: Props) => {
  const [aiProvider, setAiProvider] = useState<modelNames>();
  const [selectedProvier, selectProvider] = useState<number>();
  const [aiModel, setAiModel] = useState<string>();
  const [organisationId, setOrganisationId] = useState<string>();
  const [apiKey, setApiKey] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [providerApiKeys, setProviderApiKeys] = useState<any[]>([]);
  const [iSelectedModelId, setISelectedModelId] = useState<number>();
  const [masterUserId, setMasterUserId] = useState<number>();

  useEffect(() => {
    getUser(user.name).then((data) => {
      // Save master user id
      var _masterUserId = data.masterUserId == 0 ? data.id : data.masterUserId;

      setMasterUserId(_masterUserId);
      
      getApiKeys(_masterUserId, user.licenseType).then((data) => { 
        console.log(data, "data");
        setProviderApiKeys(data);
      });
    });
  }, []);

  useEffect(() => {
    getApiKeys(masterUserId, user.licenseType).then((data) => { 
      console.log(data, "data");
      setProviderApiKeys(data);

      var selectedApiKey = providerApiKeys.find((key) => key.providerId === selectedProvier);
      setApiKey(selectedApiKey?.apiKey);
    });
  }, [selectedProvier]);

  useEffect(() => {
    console.log("API KEY >>>>>> ", apiKey);
  }, [apiKey]);

  const updateChanges = async () => {
    console.log({
      aiProvider,
      aiModel,
      organisationId,
      apiKey,
    });
    const res = await updateUserAiSetting(
      user.accessToken,
      aiProvider,
      aiModel,
      organisationId,
      apiKey
    );
    if (res) {
      setUserWithAi();
      setUpdatedApiKey(apiKey);
      setUpdatedModel(aiModel);
      setSelectedModelId(iSelectedModelId);
      toast.success("Keys Updated!", {
        toastId: "keys-update-success",
        position: toast.POSITION.BOTTOM_LEFT,
      });
      console.log("success");
    } else {
      toast.error("Error occured", {
        toastId: "keys-update-error",
        position: toast.POSITION.BOTTOM_LEFT,
      });
      console.log("error");
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      await ingestDoc(userId);
      toast.success("Data Ingested, start chatting away!");
    } catch (error) {
      toast.error(error)
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setUserWithAi = async () => {
    const newUser = {
      ...user,
      aiProvider,
      aiModel,
      aiOrganisationId: organisationId,
      aiApiKey: apiKey,
    };
    console.log(newUser, "newUser");

    setUser(newUser);
  };

  // useEffect(() => {
  //   setAiProvider(provider);
  //   setAiModel(model);
  //   setOrganisationId(organId);
  //   setApiKey(aikey);
  // }, [provider, model, organId, aikey]);

  const models: modelType[] = useMemo(() => {
    if (aiProvider === "togetherai") return togetherModels;
    if (aiProvider === "openai") return openAiModels;
    if (aiProvider === "anthropic") return anthropicModels;
    if (aiProvider === "mistralai") return mistralModels;
    return [];
  }, [aiProvider]);

  return (
    <div className="Ai-Settings">
      <div>
        <select
          name="provider"
          id="provider"
          onChange={(e) => {
            setAiProvider(e.target.value as modelNames);
            setApiKey("");
            setOrganisationId("");

            if (e.target.value === "anthropic") {
              selectProvider(2);
            } else if (e.target.value === "openai") {
              selectProvider(1);
            } else if (e.target.value === "togetherai") {
              selectProvider(3);
            } else if (e.target.value === "mistralai") {
              selectProvider(4);
            }
          }}
          value={aiProvider}
        >
          <option value={null}>Please Select AI Provider</option>
          {providers.map((provider) => (
            <option key={provider.value} value={provider.value}>
              {provider.name}
            </option>
          ))}
        </select>
        {!aiProvider && (
          <span className="error">{"You have to select AI provider."}</span>
        )}
      </div>
      <div>
        <select
          name="model"
          id="model"
          onChange={(e) => {
            const selectedModel = models.find(model => model.value === e.target.value);
            setAiModel(e.target.value);
            setISelectedModelId(selectedModel?.modelId);
          }}
          value={aiModel}
        >
          <option value={null}>Please Select AI Model</option>
          {models.map((model) => (
            <option key={model.value} value={model.value}>
              {model.name}
            </option>
          ))}
        </select>
        {!aiModel && (
          <span className="error">{"You have to select AI model."}</span>
        )}
      </div>
      <div>
        <input
          type="text"
          name="organisationId"
          id="organisationId"
          placeholder="Organisation Id"
          defaultValue={organisationId}
          onChange={(e) => setOrganisationId(e.target.value)}
        />
        {!organisationId && (
          <span className="error">{"You have to input Organisation Id."}</span>
        )}
      </div>
      <div>
        <button type="button" className="updateBtn" onClick={updateChanges}>
          Update Changes
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user: IUser) => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AiSettings);